import * as axios from 'axios';
import { authHeader } from '../_helpers';
import { jwtService } from './jwt.service';
// import settings from '/ps_settings/config.json';

//Returns URL based on Host
const baseApiURL = function() {
    if(process.env.API_PORT)
    {
        return `${process.env.VUE_APP_API_HOST}:${process.env.API_PORT}`
    }
    else
    {
        return process.env.VUE_APP_API_HOST
    }
}

//Returns URL based on Host
const baseCapURL = function() {
    if(process.env.CAP_PORT)
    {
        return `${process.env.VUE_APP_CAP_HOST}:${process.env.CAP_PORT}`
    }
    else
    {
        return process.env.VUE_APP_CAP_HOST
    }
}

const pingApiServer = async function() {

    const requestOptions = {
        url: `${baseApiURL()}/ping-service`,
        method: 'GET',
        headers: { ...authHeader, 'Content-Type': 'application/json' }
    };

    try {
        const response = await axios(requestOptions);

        let result = handleResponse(response);

        return result;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }
}

const pingCapServer = async function() {

    const requestOptions = {
        url: `${baseCapURL()}/ping-service`,
        method: 'GET',
        headers: { ...authHeader, 'Content-Type': 'application/json' }
    };

    try {
        const response = await axios(requestOptions);
        return response.data;
    } catch (err) {
        return err;
    }
}

//Handles Server Responses
const handleResponse = (response) => {
    //Checks URL response
    if(response.status !== 200) throw Error(response.message);
    //Checks Data response
    if(response.data.error) throw Error(response.data.error.errorText);
    //Checks Request response
    if(response.request.error) throw Error(response.request.message);

    let message = response.data;

    return message;
}

export const siteService = {
    pingApiServer,
    pingCapServer
}
