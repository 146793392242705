import {contentService} from '../_services';
import {
    PERSIST_XML_CONTENT,
    PERSIST_MILESTONE_SORT,
    VALIDATE_XML_CONTENT,
    VALIDATE_MILESTONE_SORT,
    DELETE_MILESTONE_CONTENT,
    EVALUATE_CAP_CONTENT_TYPE,
    DOWNLOAD_XML_CONTENT,
    USER_MILESTONE_DATA,
    CLEAR_USER_MILESTONE_DATA,
    ARTICLE_DATA,
    GRADE_PATHWAY_INFO,
    GET_TAGGED_ARTICLES
} from './mutation-types';

const state = () => ({
    resultLinks: '',
    downloads: '',
    article_data: '',
    capContentType: '',
    user_milestone_data: {},
    grade_pathway_info: '',
    validationData: {},
    tagged_article_dict: {}
});

const mutations = {
    [USER_MILESTONE_DATA](state, data) {
        state.user_milestone_data = data;
    },
    [VALIDATE_MILESTONE_SORT](state, content) {
        state.validationData = content;
    },
    [VALIDATE_XML_CONTENT](state, content) {
        state.validationData = content;
    },
    [DELETE_MILESTONE_CONTENT](state, content) {
        state.deleteMilestoneContent = content;
    },
    [EVALUATE_CAP_CONTENT_TYPE](state, content) {
        state.capContentType = content;
    },
    [PERSIST_XML_CONTENT](state, content) {
        state.resultLinks = content;
    },
    [PERSIST_MILESTONE_SORT](state, content) {
        state.resultLinks = content;
    },
    [CLEAR_USER_MILESTONE_DATA](state) {
        state.user_milestone_data = {};
    },
    /*
    [DOWNLOAD_XML_CONTENT] (state, content) {
        state.downloads = content;
    },
    */
    [ARTICLE_DATA](state, data) {
        state.article_data = data;
    },
    [GRADE_PATHWAY_INFO](state, data) {
        state.grade_pathway_info = data;
    },
    [GET_TAGGED_ARTICLES] (state, result_dict) {
        state.tagged_article_dict = result_dict;
    }
};

const actions = {
    async get_user_milestones({commit}, data) {
        const result_dict = await contentService.get_user_milestones(data);
        if (! result_dict.data) {
            return result_dict.error_list;
        }
        const user_milestone_data = result_dict.data;
        let idx = 0;
        let zidx = '';
        const new_user_milestone_list = user_milestone_data.milestone_list.map(item => {
            idx++;
            if (idx < 10) {
                zidx = '0' + idx;
            } else {
                zidx = '' + idx;
            }
            item.milestone_order_index = zidx;
            return item;
        });
        user_milestone_data.milestone_list = new_user_milestone_list;
        commit(USER_MILESTONE_DATA, user_milestone_data);
    },
    async get_article({commit}, data) {
        const article_data = await contentService.get_article(data);
        commit(ARTICLE_DATA, article_data);
    },
    async get_grade_pathway_info({commit}, data) {
        const grade_pathway_info = await contentService.get_grade_pathway_info(data);
        commit(GRADE_PATHWAY_INFO, grade_pathway_info);
    },
    async validateXmlContent({commit}, content) {
        const validationData = await contentService.validateXmlContent(content);
        commit(VALIDATE_XML_CONTENT, validationData);
    },
    async validateMilestoneSort({commit}, content) {
        const validationData = await contentService.validateMilestoneSort(content);
        commit(VALIDATE_MILESTONE_SORT, validationData);
    },
    async persistXmlContent({commit}, content) {
        const resultLinks = await contentService.persistXmlContent(content);
        commit(PERSIST_XML_CONTENT, resultLinks);
    },
    async persistUserMilestoneProgress({commit}, param_dict) {
        await contentService.persistUserMilestoneProgress(param_dict);
    },
    async persistMilestoneSort({commit}, content) {
        const resultLinks = await contentService.persistMilestoneSort(content);
        commit(PERSIST_MILESTONE_SORT, resultLinks);
    },
    async deleteMilestoneContent({commit}, content) {
        const resultLinks = await contentService.deleteMilestoneContent(content);
        commit(DELETE_MILESTONE_CONTENT, resultLinks);
    },
    async evaluateCapContentType({commit}, content) {
        const capContentType = await contentService.evaluateCapContentType(content);
        commit(EVALUATE_CAP_CONTENT_TYPE, capContentType);
        return capContentType;
    },
    async clear_user_milestones({commit}) {
        commit(CLEAR_USER_MILESTONE_DATA);
    },
    /*
    async downloadXmlContent({ commit }, content) {
        const newDownload = await contentService.downloadXmlContent(content);
        commit(DOWNLOAD_XML_CONTENT, newDownload);
    }
     */
    async get_tagged_articles({ commit }, param_dict)  {
        const result_dict = await contentService.get_tagged_articles(param_dict);
        if (result_dict.error_list.length > 0) {
            return result_dict.error_list;
        }
        const tagged_article_dict = result_dict.data;
        commit(GET_TAGGED_ARTICLES, tagged_article_dict);
        return [];
    }

};

export const content = {
    namespaced: true,
    state,
    actions,
    mutations
}
