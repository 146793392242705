import { router, sessionStorage } from '../_helpers';
import { userService } from '../_services';
import {
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT
 } from './mutation-types';

 const user = sessionStorage.getSessionStorage();
 const state = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

 const mutations = {
    [REGISTER_REQUEST](state, user) {
        state.status = { registering: true };
    },
    [REGISTER_SUCCESS](state, user) {
        state.status = {};
    },
    [REGISTER_FAILURE](state, error) {
        state.status = {error: error};
    },
    [LOGIN_REQUEST](state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    [LOGIN_SUCCESS](state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    [LOGIN_FAILURE](state) {
        state.status = {};
        state.user = null;
    },
    [LOGOUT](state) {
        state.status = {};
        state.user = null;
    }
 };

 const actions = {
    registerUser({ dispatch, commit }, user) {
        commit(REGISTER_REQUEST, user);
    
        userService.registerUser(user)
            .then(
                user => {
                    commit(REGISTER_SUCCESS, user);
                    router.push('/confirm');
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', 'Registration successful', { root: true });
                    })
                },
                error => {
                    commit(REGISTER_FAILURE, error);
                    // router.push('registration/error');
                    // setTimeout(() => {
                    //     // display success message after route change completes
                    //     dispatch('alert/error', `Registration failed. ${error.message}`, { root: true });
                    // })
                    dispatch('alert/success', 'Registration successful', { root: true });
                    // dispatch('alert/error', error, { root: true });
                }
            );
    },
    async loginUser({ dispatch, commit }, {email, password }) {
        try {
            const login = await userService.loginUser(email, password);
            commit(LOGIN_SUCCESS, login);
        } catch(error) {
            commit(LOGIN_FAILURE, error);
            dispatch('alert/error', `Login failed. ${error.message}`, { root: true });
        }
    },
    logout({ commit, dispatch }) {
        userService.logoutUser();
        commit(LOGOUT);
        if(router.currentRoute.name !== 'landing')
        {
            // 2022-01-29. Commenting this out for later review. Generates console error on return to home page.
            // router.push('/landing');
        }
        dispatch('alert/success', 'Logged Out', { root: true });

    },
 };


 export const registration = {
     namespaced: true,
     state,
     actions,
     mutations
 };
