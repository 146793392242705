import {dbService} from '../_services';
import {
    GET_MERGED_USER_DISTRICT_SCHOOL_DATA,
    UPDATE_USER_DISTRICT_SELECTIONS,
    UPDATE_USER_SCHOOL_SELECTIONS,
    CLEAR_USER_DISTRICT_SELECTIONS,
    CLEAR_USER_SCHOOL_SELECTIONS,

    // SET_USER_DISTRICTS,
    // SET_USER_SCHOOLS,

    PERSIST_USER_DISTRICT_SELECTIONS,
    PERSIST_USER_SCHOOL_SELECTIONS,
    PERSIST_USER_PATHWAY_SELECTIONS,

    GET_GRADES,
    GET_PATHWAYS,
    GET_USER_GRADE_PATHWAY_LIST,
    GET_GRADE_PATHWAY_LIST,
    UPDATE_USER_GRADE_PATHWAY_LIST,
    CLEAR_USER_GRADE_PATHWAY_LIST,
    SET_USER_PATHWAY_SELECTIONS,
    CLEAR_USER_PATHWAY_SELECTIONS,
    // GET_MILESTONES,
    // GET_ARTICLES,
    // GET_ARTICLE,
    SET_USER_PREFERENCES,
    GET_USER_PREFERENCES,
    CLEAR_USER_PREFERENCES
} from './mutation-types';
import {alphabetize} from "../_helpers/alphabetize";


const state = () => ({
    merged_user_district_school_data: [],
    user_district_selections: [],
    user_school_selections: [],
    grades: [],
    pathways: [],
    grade_pathway_list: [],
    user_grade_pathway_list: [],
    user_pathway_selections: []
    // milestones: [],
    // articles: [],
    // article: {}
})

const mutations = {
    [GET_MERGED_USER_DISTRICT_SCHOOL_DATA](state, merged_user_district_school_data) {
        state.merged_user_district_school_data = merged_user_district_school_data;
    },

    [UPDATE_USER_DISTRICT_SELECTIONS](state, user_district_selections) {
        state.user_district_selections = user_district_selections;
    },

    [UPDATE_USER_SCHOOL_SELECTIONS](state, user_school_selections) {
        state.user_school_selections = user_school_selections;
    },
    [CLEAR_USER_DISTRICT_SELECTIONS](state) {
        // alert('CLEAR_USER_DISTRICT_SELECTIONS');
        state.user_district_selections = [];
    },
    [CLEAR_USER_SCHOOL_SELECTIONS](state) {
        // alert('CLEAR_USER_SCHOOL_SELECTIONS');
        state.user_school_selections = [];
    },

    /*
    [SET_USER_DISTRICTS](state, user_districts) {
        state.user_districts = user_districts;
    },
    [SET_USER_SCHOOLS](state, user_schools) {
        state.user_schools = user_schools;
    },
     */

    [PERSIST_USER_DISTRICT_SELECTIONS](state, user_districts) {
        state.user_district_selections = user_districts;
    },
    [PERSIST_USER_SCHOOL_SELECTIONS](state, user_schools) {
        state.user_school_selections = user_schools;
    },
    [PERSIST_USER_PATHWAY_SELECTIONS](state, user_pathway_selections) {
        state["user_pathway_selections"] = user_pathway_selections;
    },
    [GET_GRADES](state, grades) {
        state.grades = grades;
    },
    [GET_PATHWAYS](state, pathways) {
        state.pathways = pathways
    },
    [GET_GRADE_PATHWAY_LIST](state, grade_pathway_list) {
        state.grade_pathway_list = grade_pathway_list;
    },
    [GET_USER_GRADE_PATHWAY_LIST](state, user_grade_pathway_list) {

        // const index = state.user_grade_pathway_list.findIndex(i => i.grade_key === user_grade_pathway_list.grade_key);

        state.user_grade_pathway_list = user_grade_pathway_list;

        /*
        if (index < 0 && user_grade_pathway_list.pathways.length > 0) {
            state.user_grade_pathways.unshift(user_grade_pathways)
        }
        if (index >= 0) {
            state.user_grade_pathways.splice(index, 1, user_grade_pathways);
            state.user_grade_pathways = [...state.user_grade_pathways]
        }
        if (index >= 0 && user_grade_pathways.pathways.length <= 0) {
            state.user_grade_pathways.shift(user_grade_pathways)
        }
         */
    },
    [UPDATE_USER_GRADE_PATHWAY_LIST](state, user_grade_pathway_list) {
        state.user_grade_pathway_list = user_grade_pathway_list;
    },

    [CLEAR_USER_GRADE_PATHWAY_LIST](state) {
        // alert('CLEAR_USER_GRADE_PATHWAY_LIST');
        state.user_grade_pathway_list = [];
    },

    [CLEAR_USER_PATHWAY_SELECTIONS](state) {
        // alert('CLEAR_USER_PATHWAY_SELECTIONS');
        state.user_pathway_selections = [];
    },
    [SET_USER_PATHWAY_SELECTIONS](state, user_pathway_selections) {
        state.user_pathway_selections = user_pathway_selections;
    },

    [SET_USER_PREFERENCES](state, preferences) {
        let user_grade_pathway_list = [];
        let user_districts = [];
        let user_schools = [];
        preferences.forEach(preference => {
            if (preference.user_grade_pathway_list) {
                user_grade_pathway_list.push(...preference.user_grade_pathway_list)
            }
            if (preference.user_districts) {
                preference.user_districts.forEach(user_district => {
                    user_districts.push(user_district)
                });
            }
            if (preference.user_schools) {
                preference.user_schools.forEach(user_school => {
                    user_schools.push(user_school)
                });
            }
        });
        state.user_districts = user_districts;
        state.user_schools = user_schools;
        state.user_grade_pathway_list = user_grade_pathway_list;
    },

    [CLEAR_USER_PREFERENCES](state) {
        // alert('CLEAR_USER_PREFERENCES');
        state.user_districts = [];
        state.user_schools = [];
        state.user_grade_pathway_list = [];
    },

    [GET_USER_PREFERENCES](state, preferences) {
        let user_grade_pathway_list = [];
        let user_districts = [];
        let user_schools = [];
        preferences.forEach(preference => {
            if (preference.user_grade_pathway_list) {
                user_grade_pathway_list.push(...preference.user_grade_pathway_list)
            }
            if (preference.user_districts) {
                preference.user_districts.forEach(user_district => {
                    user_districts.push(user_district)
                });
            }
            if (preference.user_schools) {
                preference.user_schools.forEach(user_school => {
                    user_schools.push(user_school)
                });
            }
        });
        state.user_districts = user_districts;
        state.user_schools = user_schools;
        state.user_grade_pathway_list = user_grade_pathway_list;
    },

    /*
    [GET_MILESTONES]() {
    },
     */
    /*
    [GET_ARTICLE]() {

    },
     */
    /*
    [GET_ARTICLES]() {

    },
     */
};

let mike = 'Your profile and action plans were updated!\n' 
// var joe = 'Return to your home page'
//var joe = <pre v-bind:href="url"> ... </pre>

const actions = {
    
    //-----------------------------------------------
    //User/District/School Actions

    // Gets all district and school data merged with current user selections.
    async get_merged_user_district_school_data({commit}, oidc_id) {
        const merged_user_district_school_data = await dbService.get_merged_user_district_school_data(oidc_id);
        commit(GET_MERGED_USER_DISTRICT_SCHOOL_DATA, merged_user_district_school_data);
    },

    async update_user_district_selections({commit}, user_district_selections) {
        commit(UPDATE_USER_DISTRICT_SELECTIONS, user_district_selections);
    },

    async update_user_school_selections({commit}, user_school_selections) {
        commit(UPDATE_USER_SCHOOL_SELECTIONS, user_school_selections);
    },
    async clear_user_district_selections({commit}) {
        commit(CLEAR_USER_DISTRICT_SELECTIONS);
    },
    async clear_user_school_selections({commit}) {
        commit(CLEAR_USER_SCHOOL_SELECTIONS);
    },
    async clearUserPathwaySelections({commit}) {
        commit(CLEAR_USER_PATHWAY_SELECTIONS);
    },
    async setUserPathwaySelections({commit}, user_pathway_selections) {
        commit(SET_USER_PATHWAY_SELECTIONS, user_pathway_selections);
    },

    //Sets user district selection
    /*
    async setUserDistricts({commit}, selection) {
        const user_districts = await dbService.setUserDistricts(selection);
        user_districts.forEach(user_district => {
            user_district.is_checked = true;
        })
        commit(SET_USER_DISTRICTS, user_districts);
    },
     */

    //Sets user school selection
    /*
    async setUserSchools({commit}, selection) {
        const user_schools = await dbService.setUserSchools(selection);
        commit(SET_USER_SCHOOLS, user_schools);
    },
     */

    //Gets grades from local store
    async getGrades({commit}) {
        const grades = await dbService.getGrades();
        commit(GET_GRADES, grades);
    },
    //Gets pathways from local store
    async getPathways({commit}) {
        const pathways = await dbService.getPathways();
        commit(GET_PATHWAYS, pathways);
    },
    // Gets user_grade_pathway_list selection (grade + pathway)
    async getUserGradePathwayList({commit}, param_dict) {
        const user_grade_pathway_list = await dbService.getUserGradePathwayList(param_dict);
        commit(GET_USER_GRADE_PATHWAY_LIST, user_grade_pathway_list);
    },
    // Gets grade_pathway_list selection (grade + pathway)
    async getGradePathwayList({commit}, param_dict) {
        const grade_pathway_list = await dbService.getGradePathwayList(param_dict);
        commit(GET_GRADE_PATHWAY_LIST, grade_pathway_list);
    },
    // Clear user_grade_pathway_list
    async clearUserGradePathwayList({commit}) {
        commit(CLEAR_USER_GRADE_PATHWAY_LIST);
    },

//---------------------------------------------------
//DB Actions

    //Updates user district selection in trt db
    async persist_user_districts({commit}, param_dict) {
        let oidc_id = param_dict.oidc_id;
        let user_districts = param_dict.user_districts;
        // To be removed.  Not necessary to update districts separately from school selections.
        // const saved_district_selections = await dbService.persistUserDistricts(oidc_id, user_districts);
        // commit(PERSIST_USER_DISTRICT_SELECTIONS, saved_district_selections);
    },
    //Updates user school selection in trt db
    async persist_user_schools({commit}, param_dict) {
        let oidc_id = param_dict.oidc_id;
        let user_schools = param_dict.user_schools;
        const saved_user_schools = await dbService.persistUserSchools(oidc_id, user_schools);
        commit(PERSIST_USER_SCHOOL_SELECTIONS, saved_user_schools);
    },
    //Updates user school selection in trt db
    async persist_user_pathway_selections({commit, dispatch}, param_dict) {
        let oidc_id = param_dict.oidc_id;
        let user_pathway_selections = param_dict.user_pathway_selections;
        const saved_user_pathway_selections = await dbService.persistUserPathwaySelections(oidc_id, user_pathway_selections);
        commit(PERSIST_USER_PATHWAY_SELECTIONS, saved_user_pathway_selections);
        dispatch('alert/success', 'Your profile and action plans were updated!' , {root: true});
    },
    //Updates user_grade_pathway_list selection in trt db (school + pathway)
    async updateUserGradePathwayList({commit}, oidc_id, user_grade_pathway_list) {
        const saved_user_grade_pathway_list = await dbService.persistUserGradePathwayList(oidc_id, user_grade_pathway_list);
        commit(UPDATE_USER_GRADE_PATHWAY_LIST, saved_user_grade_pathway_list);
    },
    //Clear user_preferences
    async clearUserPreferences({commit}) {
        commit(CLEAR_USER_PREFERENCES);
    },
    //Gets user preferences from trt db (user_district, user_school, and user_grade_pathway_list (grade + pathway))
    async getUserPreferences({commit}, param_dict) {
        const preferences = await dbService.getUserPreferences(param_dict);
        commit(GET_USER_PREFERENCES, preferences);
    },
    //Set user preferences in trt db (user_district, user_school, and user_grade_pathway_list (grade + pathway))
    async setUserPreferences({commit}, preferences, lang_code) {
        const oidc_id = preferences.oidc_id;
        const user_districts = preferences.user_district_selections;
        const user_schools = preferences.user_school_selections;
        const user_pathway_selections = preferences.user_pathway_selections;
        await dbService.setUserPreferences(oidc_id, lang_code, user_districts, user_schools, user_pathway_selections);
        const newPreferences = await dbService.getUserPreferences({oidc_id: oidc_id, lang_code: lang_code});
        commit(SET_USER_PREFERENCES, newPreferences);
    },
    /*
    async getMilestones({commit}) {
        const milestones = await dbService.getMilestones();
        commit(GET_MILESTONES, milestones);
    },
     */
    /*
    async getArticle({commit}, article) {
        const selectedArticle = await dbService.getArticle(article);
        commit(GET_ARTICLE, selectedArticle);
    },
     */
    /*
    async getArticles({commit}) {
        const articles = await dbService.getArticles();
        commit(GET_ARTICLES, articles);
    },
     */
};

export const preferences = {
    namespaced: true,
    state,
    actions,
    mutations
};