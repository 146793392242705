import Vue from 'vue';
import Router from 'vue-router';
import { sessionStorage } from './session-storage';
import { userService, siteService } from "../_services";

Vue.use(Router);

const lang_name_dict = {
    "en": "English",
    "es": "Español"
}

let re_lang_only = new RegExp("^/(..).*$");
let re_lang_path = new RegExp("^/(..)(/.*)$");

let lang_code;
let lang_info;
let new_lang_path;
let new_lang_code;
let remain_path;

const lang_code_list = ["en", "es"]
const userSessionProfile = sessionStorage.decodeUserSession();

function rewrite_lang_urls() {

    // Get the current session lang_code.
    const session_lang_info = sessionStorage.getSessionLangInfo();

    if (session_lang_info) {
        lang_info = JSON.parse(session_lang_info);
    } else {
        // TODO pull from user's database profile to save in session
        // for now, use default of 'en'
        let user_profile_lang_code = "en";
        lang_info = {
            "lang_code": user_profile_lang_code,
            "lang_name": lang_name_dict[user_profile_lang_code]
        }
        sessionStorage.setSessionLangInfo(JSON.stringify(lang_info));
    }

    lang_code = lang_info["lang_code"];

    // Handle explicit call of new lang code as first and only path component.

    if (document.location.pathname.length === 3) {

        new_lang_path = re_lang_only.exec(document.location.pathname);

        if (new_lang_path) {
            new_lang_code = new_lang_path[1];
            if (new_lang_code && new_lang_code !== lang_code && lang_code_list.includes(new_lang_code)) {
                lang_code = new_lang_code;
                lang_info = {
                    "lang_code": lang_code,
                    "lang_name": lang_name_dict[lang_code]
                }
                // TODO use this URL as a trigger to also update user's database and hubspot profiles.
                sessionStorage.setSessionLangInfo(JSON.stringify(lang_info));
                if (userSessionProfile) {
                    document.location.pathname = `/${lang_code}/home`
                } else {
                    document.location.pathname = `/${lang_code}/landing`
                }
            }
        }
    }

    new_lang_path = re_lang_path.exec(document.location.pathname);

    if (new_lang_path) {
        new_lang_code = new_lang_path[1];
        remain_path = new_lang_path[2];
        if ((new_lang_code) && (new_lang_code !== lang_code) && (lang_code_list.includes(new_lang_code))) {
            lang_code = new_lang_code;
            lang_info = {
                "lang_code": new_lang_code,
                "lang_name": lang_name_dict[new_lang_code]
            }
            sessionStorage.setSessionLangInfo(JSON.stringify(lang_info));
            document.location.pathname = `/${new_lang_code}${remain_path}`;
        }
    }

    return lang_code;

}

lang_code = rewrite_lang_urls();

let defaultBasePath;

if (userSessionProfile) {
    defaultBasePath = "home";
} else {
    defaultBasePath = "landing";
}

const parseProps = r => ({ id: parseInt(r.params.id) });

export const router = new Router({
    mode: 'history',
    base: __dirname,
    routes: [
        {
            path: '/',
            name: 'root',
            // redirect: `${lang_code}/rootDispatch`
            redirect: to => ({
                path: `/${to.params.lang_new}/$defaultBasePath`
            }),
        },
        {
            path: '/lang/:lang_new',
            redirect: to => ({
                path: `/${to.params.lang_new}/landing`
            }),
        },
        {
            path: '/:lang_code/siteDown',
            name: 'siteDown',
            component: () =>
                import(/* webpackPrefetch: true, webpackChunkName: "bundle.landing" */ '../views/siteDown.vue'),
        },
        // {
        //     path: '/:lang_code/genericLogin',
        //     name: 'genericLogin',
        //     component: () =>
        //         import(/* webpackPrefetch: true, webpackChunkName: "bundle.landing" */ '../views/genericLogin.vue'),
        // },
        {
            path: '/:lang_code/admin',
            name: 'admin',
            component: () =>
                import(/* webpackChunkName: "bundle.admin" */ '../views/admin.vue'),
        },
        {
            path: '/:lang_code/admin/cap',
            name: 'cap',
            component: () =>
                import(/* webpackChunkName: "bundle.admin" */ '../views/cap.vue'),
        },
        {
            path: '/:lang_code/admin/milestones-by-display-order',
            name: 'admin_milestones_by_display_order',
            component: () =>
                import(/* webpackChunkName: "bundle.admin" */ '../views/admin_milestones_by_display_order.vue'),
        },
        {
            path: '/:lang_code/admin/articles-by-grade-pathway',
            name: 'admin_articles_by_grade_pathway',
            component: () =>
                import(/* webpackChunkName: "bundle.admin" */ '../views/admin_articles_by_grade_pathway.vue'),
        },
        {
            path: '/:lang_code/admin/articles-by-milestone',
            name: 'admin_articles_by_milestone',
            component: () =>
                import(/* webpackChunkName: "bundle.admin" */ '../views/admin_articles_by_milestone.vue'),
        },
        {
            path: '/:lang_code/admin/articles-by-milestone-strategy',
            name: 'admin_articles_by_milestone_strategy',
            component: () =>
                import(/* webpackChunkName: "bundle.admin" */ '../views/admin_articles_by_milestone_strategy.vue'),
        },
        {
            path: '/:lang_code/admin/articles-by-key',
            name: 'admin_articles_by_key',
            component: () =>
                import(/* webpackChunkName: "bundle.admin" */ '../views/admin_articles_by_key.vue'),
        },
        {
            path: '/:lang_code/admin/articles-by-tag',
            name: 'admin_articles_by_tag',
            component: () =>
                import(/* webpackChunkName: "bundle.admin" */ '../views/admin_articles_by_tag.vue'),
        },
        {
            path: `/:lang_code/landing`,
            name: 'landing',
            component: () =>
                import(/* webpackChunkName: "bundle.landing" */ '../views/landing.vue'),
        },
        {
            path: '/:lang_code/home',
            name: 'home',
            component: () =>
                import(/* webpackChunkName: "bundle.user" */ '../views/home.vue'),
        },
        {
            path: '/:lang_code/page_not_found',
            name: 'page_not_found',
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/error.vue'),
        },
        {
            path: '/:lang_code/registration',
            name: 'registration',
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/registration.vue'),
        },
        {
            path: '/:lang_code/registration/error',
            name: 'registration_error',
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/registration-error.vue'),
        },
        {
            path: '/:lang_code/registration/link/error',
            name: 'registration_link_error',
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/registration-link-error.vue'),
        },
        {
            path: '/:lang_code/onboarding',
            name: 'onboarding',
            props: parseProps,
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/onboarding.vue'),
        },
        {
            path: '/:lang_code/login',
            name: 'login',
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/login.vue'),
        },
        {
            path: '/:lang_code/confirm',
            name: 'confirm',
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/confirm.vue'),
        },
        {
            path: '/:lang_code/forgot-password',
            name: 'forgotPassword',
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/forgotPassword.vue'),
        },
        {
            path: '/:lang_code/request-password-reset',
            name: 'requestPasswordReset',
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/requestPasswordReset.vue'),
        },
        {
            path: '/:lang_code/password-reset',
            name: 'passwordReset',
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/passwordReset.vue'),
        },
        {
            path: '/:lang_code/profile',
            name: 'profile',
            props: parseProps,
            component: () =>
                import(/* webpackChunkName: "bundle.user" */ '../views/profile.vue'),
        },
        {
            path: '/:lang_code/action-plan/:grade_key/:pathway_key',
            name: 'action_plan_grade_pathway',
            component: () =>
                import(/* webpackChunkName: "bundle.milestone" */ '../views/milestones.vue'),
        },
        {
            path: '/:lang_code/actionplan/:grade_key/:pathway_key',
            name: 'actionplan_grade_pathway',
            component: () =>
                import(/* webpackChunkName: "bundle.milestone" */ '../views/milestones.vue'),
        },
        {
            path: '/:lang_code/article/:article_key',
            name: 'article',
            component: () =>
                import(/* webpackChunkName: "bundle.article" */ '../views/article.vue'),
        },
        {
            path: '/:lang_code/shared/:article_key',
            name: 'shared',
            component: () =>
                import(/* webpackChunkName: "bundle.article" */ '../views/article.vue'),
        },
        {
            path: '/:lang_code/tag/:tag',
            name: 'tagged_articles',
            component: () =>
                import(/* webpackChunkName: "bundle.article" */ '../views/tagged_articles.vue'),
        },
        {
            path: '/:lang_code/terms_of_service',
            name: 'terms_of_service',
            component: () =>
                import(/* webpackChunkName: "bundle.registration" */ '../views/terms_of_service.vue'),
        },
        {
            path: '/admin',
            redirect: to => ({
                path: `/${lang_code}/admin`,
                query: to.query
            }),
        },

        {
            path: '/admin/cap',
            redirect: to => ({
                path: `/${lang_code}/admin/cap`,
                query: to.query
            }),
        },

        {
            path: '/admin/milestones-by-display-order',
            redirect: to => ({
                path: `/${lang_code}/admin/milestones-by-display-order`,
                query: to.query
            }),
        },


        {
            path: '/admin/articles-by-grade-pathway',
            redirect: to => ({
                path: `/${lang_code}/admin/articles-by-grade-pathway`,
                query: to.query
            }),
        },

        {
            path: '/admin/articles-by-milestone',
            redirect: to => ({
                path: `/${lang_code}/admin/articles-by-milestone`,
                query: to.query
            }),
        },

        {
            path: '/admin/articles-by-milestone-strategy',
            redirect: to => ({
                path: `/${lang_code}/admin/articles-by-milestone-strategy`,
                query: to.query
            }),
        },

        {
            path: '/admin/articles-by-key',
            redirect: to => ({
                path: `/${lang_code}/admin/articles-by-key`,
                query: to.query
            }),
        },

        {
            path: '/admin/articles-by-tag',
            redirect: to => ({
                path: `/${lang_code}/admin/articles-by-tag`,
                query: to.query
            }),
        },

        {
            path: '/actionplan/:grade_key/:pathway_key',
            redirect: to => ({
                path: `/${lang_code}/actionplan/${to.grade_key}/${to.query.pathway_key}`,
                query: to.query
            }),
        },

        {
            path: '/action-plan/:grade_key/:pathway_key',
            redirect: to => ({
                path: `/${lang_code}/action-plan/${to.query.grade_key}/${to.query.pathway_key}`,
                query: to.query
            }),
        },
        {
            path: '/page_not_found',
            redirect: to => ({
                path: `/${lang_code}/page_not_found`,
                query: to.query
            }),
        },
        {
            path: '/registration',
            redirect: to => ({
                path: `/${lang_code}/registration`,
                query: to.query
            }),
        },
        {
            path: '/registration/link/error',
            redirect: to => ({
                path: `/${lang_code}/registration/link/error`,
                query: to.query
            }),
        },
        {
            path: '/confirm',
            redirect: to => ({
                path: `/${lang_code}/confirm`,
                query: to.query
            }),
        },
        //{
        // path: '/genericLogin',
        //     redirect: to => ({
        //         path: `/${lang_code}/genericLogin`,
        //         query: to.query
        //     }),
        // },
        {
            path: '/login',
            redirect: to => ({
                path: `/${lang_code}/login`,
                query: `${to.query}`
            }),
        },
        {
            path: '*',
            redirect: `/${lang_code}/${defaultBasePath}`,
        },
    ],
});

router.beforeEach((to, from, next) => {

    let new_lang_path = re_lang_path.exec(to.path);
    if (new_lang_path) {
        new_lang_code = new_lang_path[1];
        remain_path = new_lang_path[2];
        if (!lang_code_list.includes(new_lang_code)) {
            return next({ path: `/${lang_code}${remain_path}`, query: to.query });
        }
    }

    let ping_services = async () => {
        if (to.name !== 'siteDown') {
            let result = await siteService.pingCapServer();
            if (result !== 'OK') {
                return next('/siteDown');
            }
            // TODO implement API service ping method
            /*
            result = siteService.pingApiServer();
            if (result !== 'OK') {
                return next('siteDown');
            }
             */
        }
    }
    // ping_services();

    const publicPages = ['landing',
        'login',
        'login_redirect',
        'registration',
        'registration',
        'registration_error',
        'registration_link_error',
        'confirm',
        // 'genericLogin',
        'article',
        'shared',
        'forgotPassword',
        'terms_of_service',
        'requestPasswordReset',
        'passwordReset',
        'page_not_found'];

    const adminPages = [
        'cap',
        'admin',
        'admin_milestones_by_display_order',
        'admin_articles_by_grade_pathway',
        'admin_articles_by_milestone',
        'admin_articles_by_milestone_strategy',
        'admin_articles_by_key',
        'admin_articles_by_tag'];

    // let tempAuthCookie = window.$cookies.get('genericLogin');
    // if (!tempAuthCookie && to.name !== 'genericLogin') {
    //     return next('genericLogin');
    // }

    const userSessionProfile = sessionStorage.decodeUserSession();

    const authRequired = !publicPages.includes(to.name);

    if (authRequired && !userSessionProfile) {
        return next('landing');
    }

    /*
    if (authRequired && to.name === 'root') {
        return next('landing')
    }

    if (!authRequired && to.name === 'root') {
        return next('home')
    }
     */

    const adminRequired = adminPages.includes(to.name);

    if (adminRequired) {
        if (!userService.isAdminUser()) {
            return next('landing');
        }
    }


    const is_session_refreshed = sessionStorage.refreshSessionExpiresToken();

    if (!is_session_refreshed && authRequired && to.name !== 'landing') {
        userService.logoutUser();
        return next('landing');
    }

    next();

})
