import langFileEn from '../../../lang_files/lang.en.json'; //store/src/web/
import langFileEs from '../../../lang_files/lang.es.json'; //store/src/web/

import {dbService} from '../_services';

import {sessionStorage} from "../_helpers";

const lang_name_dict = {
    "en": "English",
    "es": "Español"
}

const state = () => ({
    lang_dict: {
        "en": langFileEn,
        "es": langFileEs
    }
})

const mutations = {}

const actions = {

    async persistLangChoice({commit}, packet_dict) {

        const oidc_id = packet_dict.oidc_id;
        const lang_code = packet_dict.lang_code;
        const ts_update = packet_dict.ts_update;

        let lang_name = lang_name_dict[lang_code];
        if (lang_name) {
            let lang_info = {
                "lang_code": lang_code,
                "lang_name": lang_name
            }
            await sessionStorage.setSessionLangInfo(JSON.stringify(lang_info));

            if (oidc_id && ts_update) {
                return await dbService.persistLangChoice(oidc_id, lang_code, ts_update);
            } else {
                return {};
            }
        }
    },

    async getLangInfo() {
        let lang_info;
        let session_lang_info = await sessionStorage.getSessionLangInfo();

        if (session_lang_info) {
            lang_info = JSON.parse(session_lang_info);
        } else {
            // TODO pull from user's database profile to save in session
            let user_profile_lang_code = "en";
            let lang_name = lang_name_dict[user_profile_lang_code];
            lang_info = {
                "lang_code": user_profile_lang_code,
                "lang_name": lang_name
            }
            await sessionStorage.setSessionLangInfo(JSON.stringify(lang_info));
        }

        return lang_info
    },

    async getLangStanza({commit}, lang_data) {

        const lang_code = lang_data.lang_code;
        const lang_stanza_key = lang_data.lang_stanza_key;

        if (this.state.lang_dict == null) {
            this.state.lang_dict = {
                "en": langFileEn,
                "es": langFileEs
            }
        }

        let top_dict = this.state.lang_dict[lang_code];

        return top_dict[lang_stanza_key];

    },
}

export const language = {
    namespaced: true,
    state,
    actions,
    mutations
}
