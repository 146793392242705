import {adminService} from '../_services';
import {
    GET_ADMIN_MILESTONES,
    GET_ADMIN_ARTICLES,
} from "./mutation-types";

// import {sessionStorage} from "../_helpers";

 const state = () => ({
     admin_milestone_dict: {},
     admin_article_dict: {}
 });

 const mutations = {
    [GET_ADMIN_MILESTONES] (state, result_dict) {
         state.admin_milestone_dict = result_dict;
    },
    [GET_ADMIN_ARTICLES] (state, result_dict) {
         state.admin_article_dict = result_dict;
     },
 };

 const actions = {

     async get_admin_milestones({ commit }, param_dict)  {
         const result_dict = await adminService.get_admin_milestones(param_dict);
         if (result_dict.error_list.length > 0) {
             return result_dict.error_list;
         }
         const admin_milestone_dict = result_dict.data;
         commit(GET_ADMIN_MILESTONES, admin_milestone_dict);
         return [];
     },

     async get_admin_articles({ commit }, param_dict)  {
         const result_dict = await adminService.get_admin_articles(param_dict);
         if (result_dict.error_list.length > 0) {
             return result_dict.error_list;
         }
         const admin_article_dict = result_dict.data;
         commit(GET_ADMIN_ARTICLES, admin_article_dict);
         return [];
     },


 };

 export const admin = {
     namespaced: true,
     state,
     actions,
     mutations
 }