<template>
  <div class="fluid-container footer">
    <div class="footer-copy row">
      <div class="col-md-3 footer-col">
        <div class="footer-title">{{ lang_stanza.who_we_are }}</div>
        <div class="footer-links">
          <a
            href="https://texasoncourse.org/about/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ lang_stanza.who_we_are_about_us }}</a
          >
        </div>
        <!-- <div class="footer-links">
          <a
            href="https://texasoncourse.org/about/our-team/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ lang_stanza.who_we_are_our_team }}</a
          >
        </div> -->
      </div>
      <div class="col-md-3 footer-col">
        <div class="footer-title">{{ lang_stanza.for_educators }}</div>
        <div class="footer-links">
          <a
            href="https://texasoncourse.org/educators/educator-development/texas-oncourse-academy/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ lang_stanza.for_educators_academy }}</a
          >
        </div>
        <div class="footer-links">
          <a
            href="https://texasoncourse.org/district-leaders/resources-for-you/datadive/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ lang_stanza.for_educators_datadive }}</a
          >
        </div>
        <div class="footer-links">
          <a
            href="https://texasoncourse.org/educators/curriculum-resources/texas-oncourse-college-and-career-readiness-curriculum/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ lang_stanza.for_educators_middle_school_curriculum }}</a
          >
        </div>
      </div>
      <div class="col-md-3 footer-col">
        <div class="footer-title">
          {{ lang_stanza.for_students_and_families }}
        </div>
        <div class="footer-links">
          <a
            href="https://texasoncourse.org/educators/curriculum-resources/mapmygrad/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ lang_stanza.for_students_and_families_mapmygrad }}</a
          >
        </div>
        <div class="footer-links">
          <a
            href="https://texasoncourse.org/educators/curriculum-resources/middlegalaxy/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ lang_stanza.for_students_and_families_middlegalaxy }}</a
          >
        </div>
        <div class="footer-links">
          <a
            href="https://www.mytexasfuture.org/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ lang_stanza.for_students_and_families_mytexasfuture }}</a
          >
        </div>
      </div>
      <div class="col-md-3 footer-col">
        <div class="footer-title">{{ lang_stanza.support }}</div>
        <!-- <div class="footer-links">
          <a
            href="mailto:TexasOnCourseSupport@highered.texas.gov?subject=Tomorrow Ready Texas Support Request"
            target="_blank"
            rel="noopener noreferrer"
            >{{ lang_stanza.support_support_center }}</a
          >
        </div> -->
        <div class="footer-links">
          <a
            href="mailto:TexasOnCourseSupport@highered.texas.gov?subject=Tomorrow Ready Texas Support Request"
            target="_blank"
            rel="noopener noreferrer"
            >{{ lang_stanza.support_contact_us }}</a
          >
        </div>
      </div>
    </div>
    <div class="footer-images">
      <a
        href="https://texasoncourse.org/"
        target="_blank"
        rel="noopener noreferrer"
        ><img src="../assets/TXOC logo.svg" alt="Texas On Course Logo"
      /></a>
      <a
        href="https://www.highered.texas.gov/"
        target="_blank"
        rel="noopener noreferrer"
        ><img
          src="../assets/THECB-logo-primary.svg"
          alt="Texas Higher Education Coordinating Board Logo"
      /></a>
      <a
        href="https://www.twc.state.tx.us/"
        target="_blank"
        rel="noopener noreferrer"
        ><img
          src="../assets/TWC logo.svg"
          alt="Texas Workforce Commission Logo"
      /></a>
    </div>
    <div></div>
    <div class="copyright">© Copyright 2023..</div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      loading: false,
      lang_stanza_key: "footer",
      lang_stanza: {},
      lang_info: {},
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });
  },
  methods: {
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
  },
};
</script>
<style scoped>
.footer {
  color: #fff;
  background-color: #036cae;
  margin-top: 44px;
  padding-top: 50px;
  padding-bottom: 50px;
  bottom: 0;
  width: 100%;
  font-size: 10pt;
  border-top: 8px solid #f36f3e;
}

.footer-copy .col-md-3:nth-of-type(1) {
  margin-right: -61px;
}

.footer-copy .col-md-3:nth-of-type(2) {
  margin-right: 25px;
}

.footer-copy .col-md-3:nth-of-type(3) {
  margin-right: 20px;
}

.footer-title {
  color: #ffffff;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-top: -5px;
  margin-right: 190px;
  margin-bottom: 35px;
  white-space: nowrap;
}

.footer-title:nth-of-type(1) {
  margin-left: 126px;
}

.footer-links {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  margin-bottom: 19px;
  margin-right: 190px;
}

.footer-links > a {
  color: #ffffff !important;
}

.footer-links > a:nth-of-type(1) {
  margin-left: 126px;
}

.footer-images {
  text-align: left;
  margin-left: 129px;
  margin-right: 24px;
}

.footer-images > a > img {
  height: 63px;
  width: 104px;
  margin-right: 24px;
  position: relative;
  top: 81px;
  margin-bottom: 168px;
}

.copyright {
  color: #666666;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #f5f7fa;
  padding-top: 33px;
  padding-bottom: 40px;
}

.support-link {
  margin-left: 24px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  color: #dddddd !important;
  cursor: pointer;
}

.row {
  width: 100%;
  margin: auto;
}

@media (min-width: 556px) and (max-width: 1000px) {
  .footer-copy .col-md-3 {
    max-width: 50%;
    margin-top: 35px;
    margin-bottom: 35px;
    padding-left: 0;
  }

  .footer-copy .col-md-3:nth-of-type(1) {
    margin-right: 0;
  }

  .footer-copy .col-md-3:nth-of-type(2) {
    margin-right: 0;
  }

  .footer-copy .col-md-3:nth-of-type(3) {
    margin-right: 0;
  }

  .footer-title:nth-of-type(1) {
    margin-left: 95px;
  }

  .footer-links > a:nth-of-type(1) {
    margin-left: 95px;
  }

  .footer-images {
    margin-left: 95px;
    margin-right: 24px;
  }

  .footer-images > a > img {
    height: 63px;
    width: 104px;
    margin-right: 24px;
    position: relative;
    top: 81px;
    margin-bottom: 168px;
  }

  .row {
    max-width: 768px;
    margin: auto;
  }
}

@media (max-width: 555px) {
  .footer-title {
    margin-top: 35px;
    margin-bottom: 19px;
  }

  .footer-title:nth-of-type(1) {
    margin-left: 26px;
  }

  .row > .footer-col:first-child > .footer-title {
    margin-top: 0px;
  }

  .footer-links > a:nth-of-type(1) {
    margin-left: 26px;
  }

  .footer-images {
    margin-left: 5px;
    margin-right: 5px;
  }

  .footer-images > a > img {
    height: 55px;
    width: 90px;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    top: 81px;
    margin-bottom: 168px;
  }

  .footer-images > a:nth-child(1) > img {
    margin-left: 35px;
  }

  .row {
    max-width: 375px;
    margin: 0;
  }
}

@media (min-width: 360px) and (max-width: 400px) {
  .footer {
    border-top: 20px solid #f36f3e;
    padding-top: 64px;
  }

  .footer-title:nth-of-type(1) {
    margin-left: 45px;
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
  }

  .footer-links > a:nth-of-type(1) {
    margin-left: 45px;
  }
}
</style>
