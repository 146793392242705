import { dbService } from '../_services';
import {
    CREATE_USER_PROFILE,
    GET_USER_PROFILE_BY_EMAIL,
    GET_USER_PROFILE_BY_OIDC,
    UPDATE_USER_PROFILE,
    CLEAR_USER_PROFILE,
    REQUEST_PASSWORD_RESET,
    PERSIST_DATE_LAST_LOGIN,
    PERSIST_DATE_UPDATE
} from "./mutation-types";

import {sessionStorage} from "../_helpers";

 const state = () => ({
     userProfile: {},
     userSessionProfile: {},
     request_password_reset_result_dict: {},
     ts_last_login: '',
     ts_update: ''
});

const mutations = {
    [CREATE_USER_PROFILE] (state, newProfile) {
         state.userProfile = newProfile;
    },
    [GET_USER_PROFILE_BY_EMAIL] (state, userProfile) {
         state.userProfile = userProfile;
     },
    [GET_USER_PROFILE_BY_OIDC] (state, userProfile) {
        state.userProfile = userProfile;
    },
    [UPDATE_USER_PROFILE] (state, updatedProfile) {
        state.userProfile = updatedProfile;
    },
    [CLEAR_USER_PROFILE] (state) {
         state.userProfile = {};
     },
     [REQUEST_PASSWORD_RESET] (state, request_password_reset_result_dict) {
        state.request_password_reset_result_dict = request_password_reset_result_dict;
    },
     [PERSIST_DATE_LAST_LOGIN] (state, ts_last_login) {
         state.ts_last_login = ts_last_login;
     },
     [PERSIST_DATE_UPDATE] (state, ts_update) {
         state.ts_update = ts_update;
     }
 };

 const actions = {
     async createUserProfile({ commit }, profileData) {
         const newProfile = await dbService.createUserProfile(profileData);
         commit(CREATE_USER_PROFILE, newProfile);
     },
     async clearUserProfile({ commit }) {
         // alert('clearing user profile from store');
         commit(CLEAR_USER_PROFILE);
     },
     async getUserProfileByOidc({ commit }, oidc_id) {
         const currentProfile = await dbService.getUserProfileByOidc(oidc_id);
         commit(GET_USER_PROFILE_BY_OIDC, currentProfile);
     },
     async getUserProfileByEmail({ commit }, email) {
         const currentProfile = await dbService.getUserProfileByEmail(email);
         commit(GET_USER_PROFILE_BY_EMAIL, currentProfile);
     },
     async updateUserProfile({ commit, dispatch }, profileData ) {
         const oidc_id = profileData.oidc_id;
         const params = profileData.params;

         const updatedProfile = await dbService.updateUserProfile( oidc_id, params );

         commit(UPDATE_USER_PROFILE, updatedProfile);
         // dispatch('alert/success', 'Profile Updated', { root: true });
     },
     async requestPasswordReset({ commit }, param_dict) {
         const result_dict = await dbService.requestPasswordReset(param_dict);
         commit(REQUEST_PASSWORD_RESET, result_dict);
     },

     async persistDateLastLogin({commit}, packet_dict) {
         const oidc_id = packet_dict.oidc_id;
         const ts_last_login = packet_dict.ts_last_login;
         const result_dict = await dbService.persistDateLastLogin(oidc_id, ts_last_login);
         commit(PERSIST_DATE_LAST_LOGIN, ts_last_login);
         return result_dict;
     },

     async persistDateUpdate({commit}, packet_dict) {
         const oidc_id = packet_dict.oidc_id;
         const ts_update = packet_dict.ts_update;
         const result_dict = await dbService.persistDateUpdate(oidc_id, ts_update);
         commit(PERSIST_DATE_UPDATE, ts_update);
         return result_dict;
     },

 };

 export const account = {
     namespaced: true,
     state,
     actions,
     mutations
 }