import * as axios from 'axios';
import { authHeader } from '../_helpers';
import { jwtService } from './jwt.service';
// import settings from '/ps_settings/config.json';
const NodeRSA = require('node-rsa');
const key = new NodeRSA();

// const JWT_SECRET_CAP = process.env.JWT_SECRET_CAP;
const PRIVATE_KEY = process.env.VUE_APP_PRIVATE_KEY
key.importKey(PRIVATE_KEY, 'pkcs8');
const publicKey = key.exportKey('public');
const privateKey = key.exportKey('private');

//Returns URL based on Host
const baseCapURL = function() {
    if(process.env.CAP_PORT)
    {
        return `${process.env.VUE_APP_CAP_HOST}:${process.env.CAP_PORT}`
    }
    else
    {
        return process.env.VUE_APP_CAP_HOST
    }
}

const get_admin_milestones = async function(param_dict) {

    let packet = jwtService.jwt_encode(param_dict, privateKey);

    const requestOptions = {
        url: `${baseCapURL()}/admin-milestones`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    }

    try {
        const response = await axios(requestOptions);

        const result_dict = handleResponse(response);

        // packet = jwtService.jwt_decode(result, JWT_SECRET_CAP);

        return result_dict;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }

}

const get_admin_articles = async function(param_dict) {

    let packet = jwtService.jwt_encode(param_dict, privateKey);

    const requestOptions = {
        url: `${baseCapURL()}/admin-articles`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    }

    try {
        const response = await axios(requestOptions);

        const result_dict = handleResponse(response);

        // packet = jwtService.jwt_decode(result, JWT_SECRET_CAP);

        return result_dict;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }

}

//Handles Server Responses
const handleResponse = (response) => {
    //Checks URL response
    if(response.status !== 200) throw Error(response.message);
    //Checks Data response
    if(response.data.error) throw Error(response.data.error.errorText);
    //Checks Request response
    if(response.request.error) throw Error(response.request.message);

    let message = response.data;
    
    return message;
}

export const adminService = {
    get_admin_milestones,
    get_admin_articles
}
