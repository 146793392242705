import * as axios from 'axios';
import { authHeader } from '../_helpers';
import { jwtService } from './jwt.service';
// import settings from '/ps_settings/config.json';
const NodeRSA = require('node-rsa');
const key = new NodeRSA();

// const JWT_SECRET_CAP = settings.JWT_SECRET_CAP;
const PRIVATE_KEY = process.env.VUE_APP_PRIVATE_KEY;
key.importKey(PRIVATE_KEY, 'pkcs8');
const publicKey = key.exportKey('public');
const privateKey = key.exportKey('private');

//Returns URL based on Host
const baseCapURL = function() {
    if(process.env.CAP_PORT)
    {
        return `${process.env.VUE_APP_CAP_HOST}:${process.env.CAP_PORT}`
    }
    else
    {
        return process.env.VUE_APP_CAP_HOST
    }
}

const validateXmlContent = async function(data) {

    const packet = jwtService.jwt_encode(data, privateKey);

    const requestOptions = {
        url: `${baseCapURL()}/validate-xml-content`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    };

    try {
        const response = await axios(requestOptions);

        let result = handleResponse(response);

        return result;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }
}

const validateMilestoneSort = async function(data) {

    const packet = jwtService.jwt_encode(data, privateKey);

    const requestOptions = {
        url: `${baseCapURL()}/validate-milestone-sort`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    };

    try {
        const response = await axios(requestOptions);

        let result = handleResponse(response);

        return result;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }
}

const evaluateCapContentType = async function(data) {

    const packet = jwtService.jwt_encode(data, privateKey);

    const requestOptions = { 
        url: `${baseCapURL()}/evaluate-cap-content-type`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    };  

    try {
        const response = await axios(requestOptions);

        let result = handleResponse(response);

        return result;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }   
}

const persistUserMilestoneProgress = async function(param_dict) {

    const packet = jwtService.jwt_encode(param_dict, privateKey);

    const requestOptions = {
        url: `${baseCapURL()}/persist-user-progress-milestone`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    };

    try {
        const response = await axios(requestOptions);

        let result_dict = handleResponse(response);

    }   catch(error) {
        console.error(error);
        // return Promise.reject(error);
    }

}

const persistXmlContent = async function(data) {

    const packet = jwtService.jwt_encode(data, privateKey);

    const requestOptions = {
        url: `${baseCapURL()}/persist-xml-content`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    };

    try {
        const response = await axios(requestOptions);

        let result = handleResponse(response);

        return result;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }
}

const deleteMilestoneContent = async function(data) {

    const packet = jwtService.jwt_encode(data, privateKey);

    const requestOptions = {
        url: `${baseCapURL()}/delete-milestone-content`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    };

    try {
        const response = await axios(requestOptions);

        let result = handleResponse(response);

        return result;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }
}

const persistMilestoneSort = async function(data) {

    const packet = jwtService.jwt_encode(data, privateKey);

    const requestOptions = { 
        url: `${baseCapURL()}/persist-milestone-sort`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    };  

    try {
        const response = await axios(requestOptions);

        let result = handleResponse(response);

        return result;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }   
}

const get_grade_pathway_info = async function(data) {

    let grade_key = data.grade_key;
    let pathway_key = data.pathway_key;

    const requestOptions = {
        url: `${baseCapURL()}/grade-pathway`,
        method: 'GET',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        params: {"grade_key": grade_key,
                 "pathway_key": pathway_key
                 }
    }

    try {
        const response = await axios(requestOptions);
        const result = handleResponse(response);
        return result;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }

}

const get_user_milestones = async function(data) {

    let packet = jwtService.jwt_encode(data, privateKey);

    const requestOptions = {
        url: `${baseCapURL()}/milestones`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    }

    try {
        const response = await axios(requestOptions);

        const result_dict = handleResponse(response);

        // packet = jwtService.jwt_decode(result, JWT_SECRET_CAP);

        return result_dict;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }

}

const get_article = async function(data) {

    let article_key = data.article_key;
    let lang_code = data.lang_code;

    const requestOptions = {
            url: `${baseCapURL()}/article`,
            method: 'POST',
            headers: { ...authHeader, 'Content-Type': 'application/json' },
            data: { 'article_key': article_key,
                    'lang_code': lang_code }
        }

    try {
        const response = await axios(requestOptions);

        const result = handleResponse(response);

        // packet = jwtService.jwt_decode(result, JWT_SECRET_CAP);

        return result;

    }   catch(error) {
        return Promise.reject(error);
    }   

}

const get_tagged_articles = async function(param_dict) {

    let packet = jwtService.jwt_encode(param_dict, privateKey);

    const requestOptions = {
        url: `${baseCapURL()}/tagged-articles`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { 'packet': packet }
    }

    try {
        const response = await axios(requestOptions);

        const result_dict = handleResponse(response);

        return result_dict;

    }   catch(error) {
        console.error(error);
        return Promise.reject(error);
    }

}


//Handles Server Responses
const handleResponse = (response) => {
    //Checks URL response
    if(response.status !== 200) throw Error(response.message);
    //Checks Data response
    if(response.data.error) throw Error(response.data.error.errorText);
    //Checks Request response
    if(response.request.error) throw Error(response.request.message);

    let message = response.data;
    
    return message;
}

export const contentService = { 
    validateXmlContent,
    validateMilestoneSort,
    evaluateCapContentType,
    deleteMilestoneContent,
    persistXmlContent,
    persistMilestoneSort,
    persistUserMilestoneProgress,
    get_user_milestones,
    get_article,
    get_grade_pathway_info,
    get_tagged_articles
}
