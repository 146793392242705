// import settings from '/ps_settings/config.json';

let jsonwebtoken = require('jsonwebtoken');

const jwt_encode = (indata,
    privateKey,
    refreshable = false,
    expiresIn = "3h") => {

    indata.refreshable = refreshable;

    try {
        const token = jsonwebtoken.sign(indata,
            privateKey,
            { algorithm: 'RS256', expiresIn }
        )
        return token

    } catch (e) {
        throw {
            "error": {
                "errorCode": 401,
                "errorText": e
            }
        }
    }
}

const jwt_decode = (token, publicKey) => {
    try {
        let origData = jsonwebtoken.verify(token, publicKey, { algorithms: ['RS256'] })
        return origData;
    } catch (e) {
        throw {
            "error": {
                "errorCode": 401,
                "errorText": e
            }
        }
    }
}

export const jwtService = {
    jwt_encode,
    jwt_decode,
}
