import * as axios from 'axios';
import { sessionStorage } from '../_helpers';
// import settings from '/ps_settings/config.json';
import { jwtService } from './jwt.service';
import {jwt_decode} from "../_helpers/jwt-helper";
import { account } from "../store/account.module";
import { content } from "../store/content.module";
import { preferences } from "../store/preferences.module";
import { Commit, mapActions, mapState } from "vuex";
const NodeRSA = require('node-rsa');
const key = new NodeRSA();

const AUTH_EXPIRES = process.env.VUE_APP_AUTH_SESSION_EXPIRES_MILLISECONDS;
const AUTH_EXPIRES_SECONDS = AUTH_EXPIRES / 1000;


// const JWT_SECRET_AUTH = settings['JWT_SECRET_AUTH'];
const PRIVATE_KEY = process.env.VUE_APP_PRIVATE_KEY;
key.importKey(PRIVATE_KEY, 'pkcs8');
const publicKey = key.exportKey('public');
const privateKey = key.exportKey('private');

const ADMIN_EMAIL_LIST = process.env.VUE_APP_ADMIN_EMAIL_LIST.split(' ');

//Returns URL based on Host
const baseUrl = process.env.VUE_APP_API_HOST
const baseURL = function() {
    if(process.env.PORT)
    {
        return `${process.env.VUE_APP_API_HOST}:${process.env.PORT}`
    }
    else
    {
        return process.env.VUE_APP_API_HOST
    }
}


//Registers user
const registerUser = async function(user) {

    const data = jwtService.jwt_encode({ "user": user }, privateKey);

    const requestOptions = {
        url: `${baseURL()}/api/v1/auth/register`,
        method: 'POST',
        timeout: 30000,
        headers: {'Content-Type': 'application/json' },
        data: {data: data}
    };

    try {
        const response = await axios(requestOptions);
        let data = handleResponse(response);
        sessionStorage.clearSessionStorage();
        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
}

//Log user in
const loginUser = async function(email, password) {

    const default_jwt_token_expire_seconds = 157788000;

    const indata = {"email": email,
                    "password": password,
                    "expires": default_jwt_token_expire_seconds
                   }

    const data = jwtService.jwt_encode(indata, privateKey);

    const requestOptions = {
        url: `${baseURL()}/api/v1/auth/login`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: {data: data}
    };

    try {
        const response = await axios(requestOptions);
        let data = handleResponse(response);
        await sessionStorage.clearSessionStorage();
        await sessionStorage.clearSessionExpiresToken();
        let jwtToken = data.jwtToken;
        sessionStorage.setSessionStorage(jwtToken);
        return data;
    }   catch(error) {
        return Promise.reject(error);
    }
}

//Logs user out
const logoutUser = async function() {
    await sessionStorage.clearSessionStorage();
    await sessionStorage.clearSessionExpiresToken();
    account.state.userProfile = {};
    account.state.userSessionProfile = {};
    content.state.user_milestone_data = {};
    preferences.state.merged_user_district_school_data = [];
    preferences.state.user_district_selections = [];
    preferences.state.user_school_selections = [];
    preferences.state.user_grade_pathway_list = [];
    preferences.state.user_pathway_selections = [];
}

const handleResponse = (response) => {
    //Checks URL response
    if(response.status !== 200) throw Error(response.message);

    //Checks Data response
    if(response.data.error) {
        if (response.data.error.errorMessage) {
            throw Error(response.data.error.errorMessage);
        } else {
            throw Error(response.data.error.errorText);
        }
    }
    //Checks Request response
    if(response.request.error) throw Error(response.request.message);

    let message = response.data;
    
    return message;
};

const isAdminUser = function(resource) {

    let userSessionProfile = sessionStorage.decodeUserSession();
    if (! userSessionProfile || ! userSessionProfile.sub || ! userSessionProfile.email) {
        return false;
    }
    // TODO later lookup for user access controls permissions will be done by oidc_id, not email
    // oidc_id = userSessionProfile.oidc_id;

    let email = userSessionProfile.email;
    email = email.toLowerCase();
    return ADMIN_EMAIL_LIST.includes(email);
}

export const userService = {
    registerUser,
    loginUser,
    logoutUser,
    isAdminUser
};
