//Registration
export const REGISTER_REQUEST = 'registerRequest';
export const REGISTER_SUCCESS = 'registerSuccess';
export const REGISTER_FAILURE = 'registerFailure';
export const LOGIN_REQUEST = 'loginRequest';
export const LOGIN_SUCCESS = 'loginSuccess';
export const LOGIN_FAILURE = 'loginFailure';
export const LOGOUT = 'logout';
// Admin
export const GET_ADMIN_MILESTONES = 'get_admin_milestones';
export const GET_ADMIN_ARTICLES = 'get_admin_articles';
//TRT Database
export const REQUEST_PASSWORD_RESET = 'requestPasswordReset';
export const CREATE_USER_PROFILE = "createUserProfile";
export const GET_USER_PROFILE_BY_EMAIL = 'getUserProfileByEmail';
export const GET_USER_PROFILE_BY_OIDC = 'getUserProfileByOidc';
export const UPDATE_USER_PROFILE = 'updateUserProfile';
export const CLEAR_USER_PROFILE = 'clearUserProfile';
export const PERSIST_DATE_LAST_LOGIN = 'persistDateLastLogin';
export const PERSIST_DATE_UPDATE = 'persistDateUpdate';

//Preferences
export const GET_MERGED_USER_DISTRICT_SCHOOL_DATA = 'get_merged_user_district_school_data';
export const SET_USER_PATHWAY_SELECTIONS = 'set_user_pathway_selections';
export const UPDATE_USER_DISTRICT_SELECTIONS = 'update_user_district_selections';
export const UPDATE_USER_SCHOOL_SELECTIONS = 'update_user_school_selections';
export const CLEAR_USER_DISTRICT_SELECTIONS = 'clear_user_district_selections';
export const CLEAR_USER_SCHOOL_SELECTIONS = 'clear_user_school_selections';

// export const SET_USER_DISTRICTS = 'setUserDistricts';
// export const SET_USER_SCHOOLS = 'setUserSchools';
export const PERSIST_USER_DISTRICT_SELECTIONS = 'persistUserDistricts';
export const PERSIST_USER_SCHOOL_SELECTIONS = 'persistUserSchools';
export const PERSIST_USER_PATHWAY_SELECTIONS = 'persistUserPathwaySelections';

export const GET_GRADES = 'getGrades';
export const GET_PATHWAYS = 'getPathways';
export const GET_USER_GRADE_PATHWAY_LIST = 'getUserGradePathwayList'
export const GET_GRADE_PATHWAY_LIST = 'getGradePathwayList'
export const UPDATE_USER_GRADE_PATHWAY_LIST = 'updateUserGradePathwayList';
export const CLEAR_USER_GRADE_PATHWAY_LIST = 'clearUserGradePathwayList';
// export const GET_MILESTONES = 'getMilestones';
// export const GET_ARTICLE = 'getArticle';
// export const GET_ARTICLES = 'getArticles';
export const SET_USER_PREFERENCES = 'setUserPreferences';
export const GET_USER_PREFERENCES = 'getUserPreferences';
export const CLEAR_USER_PREFERENCES = 'clearUserPreferences';
export const CLEAR_USER_PATHWAY_SELECTIONS = 'clearUserPathwaySelections';

//Content
export const PERSIST_XML_CONTENT = 'persistXmlContent';
export const PERSIST_MILESTONE_SORT = 'persistMilestoneSort';
export const DELETE_MILESTONE_CONTENT = 'deleteMilestoneContent';
export const VALIDATE_XML_CONTENT = 'validateXmlContent';
export const VALIDATE_MILESTONE_SORT = 'validateMilestoneSort';
export const EVALUATE_CAP_CONTENT_TYPE = 'evaluateCapContentType';
// export const DOWNLOAD_XML_CONTENT = 'downloadXmlContent';
export const USER_MILESTONE_DATA = 'get_user_milestones';
export const CLEAR_USER_MILESTONE_DATA = 'clear_user_milestones';
export const ARTICLE_DATA = 'get_article';
export const GRADE_PATHWAY_INFO = 'get_grade_pathway_info';
export const GET_TAGGED_ARTICLES = 'get_tagged_articles';
//Languages
// Obsolete, use newer lang_code settings.
// export const TRANSLATE_PAGE = 'translatePage';
