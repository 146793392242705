import * as axios from 'axios';
import { authHeader } from '../_helpers';
import { sessionStorage } from '../_helpers';
import { jwtService } from './jwt.service';
// import settings from '/ps_settings/config.json';
import gradeData  from '../_data/grade-data.json';
import pathwayData  from '../_data/pathway-data.json';
import localData from '../_data/local-data.json';
import { alphabetize } from '../_helpers/alphabetize';
import {GET_ADMIN_MILESTONES} from "../store/mutation-types";
const NodeRSA = require('node-rsa');
const key = new NodeRSA();

// const JWT_SECRET_AUTH = process.env.JWT_SECRET_AUTH;
const PRIVATE_KEY = process.env.VUE_APP_PRIVATE_KEY;
key.importKey(PRIVATE_KEY, 'pkcs8');
const publicKey = key.exportKey('public');
const privateKey = key.exportKey('private');

//Returns URL based on Host
const baseURL = function() {
    if(process.env.API_PORT)
    {
        return `${process.env.VUE_APP_API_HOST}:${process.env.PORT}`
    }
    else
    {
        return process.env.VUE_APP_API_HOST
    }
}

//Returns CAP URL based on Host
const baseCapURL = function() {
    if(process.env.CAP_PORT)
    {
        return `${process.env.VUE_APP_CAP_HOST}:${process.env.PORT}`
    }
    else
    {
        return process.env.VUE_APP_CAP_HOST
    }
}

//Creates DB User Profile Entry
const createUserProfile = async function(userProfile) {
    const requestOptions = {
        url: `${baseURL()}/create-user_profile`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: userProfile
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};
//Retrieves DB User Profile by email parameter
const getUserProfileByEmail = async function(email) {
    const requestOptions = {
        url: `${baseURL()}/user_profile`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { email: email }
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};
//Retrieves DB User Profile by oidc_id parameter
const getUserProfileByOidc = async function(oidc_id) {
    const requestOptions = {
        url: `${baseURL()}/user-profile`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { oidc_id: oidc_id }
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};
//Updates DB User Profile Entry
const updateUserProfile = async function(oidc_id, param) {
    const requestOptions = {
        url: `${baseURL()}/update-user-profile`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { oidc_id: oidc_id,
                param: param
              }
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};
/*
Sets User Preferences: 
 - Email
 - Selected Districts
 - Selected Schools
 - Selected User Grade Pathways (Grade + Pathway)
*/
const setUserPreferences = async function(oidc_id, lang_code, districts, schools, user_pathway_selections) {

    const requestOptions = {
        url: `${baseURL()}/set-user-preferences`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: {oidc_id: oidc_id,
               lang_code: lang_code,
               districts: districts,
               schools: schools,
               user_pathway_selections: user_pathway_selections
              }
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};
/*
Gets User Preferences: 
 - Email
 - Selected Districts
 - Selected Schools
 - Selected Grade Pathways (Grade + Pathway)
*/
const getUserPreferences = async function(param_dict) {
    const requestOptions = {
        url: `${baseURL()}/get-user-preferences`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: param_dict
    };

    try {
        const response = await axios(requestOptions);
        let data = handleResponse(response);
        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};
//Update Preferences
//Districts
const updateUserDistricts = async function(oidc_id, districts) {
    const requestOptions = {
        url: `${baseURL()}/update-user-districts`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: { oidc_id: oidc_id,
                districts: districts
        }
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};
//Update Preferences
//Schools
const persistUserSchools = async function( oidc_id, user_schools ) {
    const requestOptions = {
        url: `${baseCapURL()}/persist-user-schools`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data: {
            oidc_id: oidc_id,
            user_schools: user_schools
        }
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data.data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};
//Update Preferences
//User Grade Pathways (Grade + Pathways)
const persistUserPathwaySelections = async function( oidc_id, user_pathway_selections ) {
    const requestOptions = {
        url: `${baseCapURL()}/persist-user-pathway-selections`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data:  {oidc_id: oidc_id,
                user_pathway_selections: user_pathway_selections }
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};
const persistUserGradePathwayList = async function( oidc_id, user_grade_pathway_list ) {
    const requestOptions = {
        url: `${baseCapURL()}/persist-user-grade-pathways`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data:  {oidc_id: oidc_id,
            user_grade_pathway_list: user_grade_pathway_list }
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};

const persistDateLastLogin = async function( oidc_id, ts_last_login ) {
    const requestOptions = {
        url: `${baseCapURL()}/persist-date-last-login`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data:  {oidc_id: oidc_id,
            ts_last_login: ts_last_login }
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};

const persistDateUpdate = async function( oidc_id, ts_update ) {
    const requestOptions = {
        url: `${baseCapURL()}/persist-date-update`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data:  {oidc_id: oidc_id,
            ts_update: ts_update }
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};

const persistLangChoice = async function( oidc_id, lang_code, ts_update ) {
    const requestOptions = {
        url: `${baseCapURL()}/persist-lang-choice`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data:  {oidc_id: oidc_id,
                lang_code: lang_code,
                ts_update: ts_update }
};

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};

// Request password reset
const requestPasswordReset = async function( param_dict ) {

    let packet = jwtService.jwt_encode(param_dict, privateKey);

    const requestOptions = {
        url: `${baseURL()}/request-password-reset`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data:  {data: packet}
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
};
//------------------------------------------
//LOCAL FUNCTIONS | "../_data"

//Returns Districts and Schools Data from the Local Store
const get_merged_user_district_school_data = async function(oidc_id) {
    let param_dict = {"oidc_id": oidc_id}
    const requestOptions = {
        url: `${baseCapURL()}/merged-user-district-school-data`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data:  {param_dict: param_dict}
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
    /*
    let data = [];
    localData.forEach(entry => {
        data.push(entry)
    });
    data.sort((a, b) => alphabetize(a.name, b.name) )
    return data;
     */
};

/*
t dbService.get_admin_milestones(param_dict);
commit(GET_ADMIN_MILESTONES, result_dict);
},
async get_admin_articles({ commit }, param_dict)  {
    const result_dict = await dbService.get_admin_articles(param_dict);
 */

//Returns Districts and Schools Data from the Local Store
const get_admin_milestones = async function(param_dict) {
    const requestOptions = {
        url: `${baseCapURL()}/admin-milestones`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data:  {param_dict: param_dict}
    };

    try {
        const response = await axios(requestOptions);

        let data = handleResponse(response);

        return data;
    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
    /*
    let data = [];
    localData.forEach(entry => {
        data.push(entry)
    });
    data.sort((a, b) => alphabetize(a.name, b.name) )
    return data;
     */
};



//Set user selected districts
const setUserDistricts = async function(selection) {
    const districts = selection;
    return districts;
}
//Set user selected schools
const setUserSchools = async function(selection) {
    const schools = selection;
    return schools;
}
//Returns Grade Pathways (Grade + Pathway)
const getGradePathwayList = async function(param_dict) {

    const requestOptions = {
        url: `${baseCapURL()}/grade-pathways`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data:  {param_dict: param_dict}
    };

    // TODO add redis cache check on grade+pathway JSON items to CAP database API

    try {
        const response = await axios(requestOptions);

        let result_dict = handleResponse(response);

        return result_dict.data;

    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
}
//Returns User Grade Pathways (User + Grade + Pathway)
const getUserGradePathwayList = async function(param_dict) {

    const requestOptions = {
        url: `${baseCapURL()}/user-grade-pathways`,
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        data:  {param_dict: param_dict}
    };

    // TODO add redis cache check on grade+pathway JSON items to CAP database API

    try {
        const response = await axios(requestOptions);

        let result_dict = handleResponse(response);

        return result_dict.data;

    }   catch(error) {
        // console.error(error);
        return Promise.reject(error);
    }
}
//Returns Grades
const getGrades = async function() {
    const grades = gradeData.grades;
    return grades
};
//Returns Pathways
const getPathways = async function() {
    const pathways = pathwayData.pathways;
    return pathways
};
//Handles Server Responses
const handleResponse = (response) => {
    //Checks URL response
    if(response.status !== 200) throw Error(response.message);
    //Checks Data response
    if(response.data.error) throw Error(response.data.error.errorText);
    //Checks Request response
    if(response.request.error) throw Error(response.request.message);
    return response.data;
};

export const dbService = {
    createUserProfile,
    getUserProfileByEmail,
    getUserProfileByOidc,
    updateUserProfile,
    requestPasswordReset,
    get_merged_user_district_school_data,
    setUserDistricts,
    updateUserDistricts,
    setUserSchools,
    persistUserSchools,
    persistUserPathwaySelections,
    getGradePathwayList,
    getUserGradePathwayList,
    persistUserGradePathwayList,
    getGrades,
    getPathways,
    setUserPreferences,
    getUserPreferences,
    persistLangChoice,
    persistDateLastLogin,
    persistDateUpdate,
    get_admin_milestones
}
