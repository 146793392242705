// import settings from '/ps_settings/config.json';
const NodeRSA = require('node-rsa');
const key = new NodeRSA();
const { jwt_encode, jwt_decode } = require('../_helpers/jwt-helper');
// const JWT_SECRET_AUTH = settings.JWT_SECRET_AUTH;
const AUTH_EXPIRES = process.env.VUE_APP_AUTH_SESSION_EXPIRES_MILLISECONDS;
const PRIVATE_KEY = process.env.VUE_APP_PRIVATE_KEY
key.importKey(PRIVATE_KEY, 'pkcs8');
const publicKey = key.exportKey('public');
const privateKey = key.exportKey('private');
// const AUTH_EXPIRES = 1128000;

const calc_next_session_expires = () => {
   return +new Date() + AUTH_EXPIRES;
}

const setSessionExpiresToken = value => {
    window.localStorage.setItem('trt_expires_token', value);
}

const getSessionExpiresToken = () => {
    return window.localStorage.getItem('trt_expires_token');
}

const clearSessionExpiresToken = async () => {
    window.localStorage.removeItem('trt_expires_token');
}

const setSessionStorage = value => {
    setSessionExpiresToken(calc_next_session_expires());
    window.localStorage.setItem('trt_session_token', value);
}

const getSessionStorage = () => {
    return window.localStorage.getItem('trt_session_token');
}

const clearSessionStorage = async () => {
    window.localStorage.removeItem('trt_session_token');
}

const setSessionLangInfo = value => {
    window.localStorage.setItem('trt_lang_info', value);
}

const getSessionLangInfo = () => {
    return window.localStorage.getItem('trt_lang_info');
}

const decodeUserSession = () => {
    try {
        let sessionData = getSessionStorage()
        const userProfile = jwt_decode(sessionData, publicKey);
        if (! userProfile.sub) {
            return null;
        }
        return userProfile;
    } catch(err) {
        return null
    }
}

const isTokenSessionExpired = () => {
    const tokenSessionExpires = getSessionExpiresToken();
    const current_milliseconds = +new Date();
    if (! tokenSessionExpires) {
        return true;
    } else {
        return current_milliseconds > tokenSessionExpires;
    }
}

const refreshSessionExpiresToken = () => {
    try {
        if (isTokenSessionExpired()) {
            clearSessionStorage();
            clearSessionExpiresToken();
            // setSessionExpiresToken(+new Date());
            return false;
        } else {
            setSessionExpiresToken(calc_next_session_expires());
            return true;
        }
    } catch(err) {
        return false;
    }
}

export const sessionStorage = {
    getSessionExpiresToken,
    setSessionExpiresToken,
    clearSessionExpiresToken,
    getSessionStorage,
    setSessionStorage,
    clearSessionStorage,
    decodeUserSession,
    refreshSessionExpiresToken,
    setSessionLangInfo,
    getSessionLangInfo
}
