<template>
  <div :key="refresh">
    <b-navbar-nav>
      <b-navbar toggleable="lg" type="light" class="header-body">
        <div v-show="status.loggedIn">
          <b-navbar-brand :to="`/${lang_info.lang_code}/home`">
            <div class="header-logo">
              <img
                class="desktop-logo"
                src="../assets/TRT_logo_serif.png"
                alt="tomorrow ready texas logo"
              />
              <div class="mobile-logo">
                <img
                  src="../assets/TRT_logo_mobile.png"
                  alt="tomorrow ready texas mobile logo"
                />
              </div>
            </div>
          </b-navbar-brand>
        </div>

        <div v-show="!status.loggedIn">
          <b-navbar-brand :to="`/${lang_info.lang_code}/landing`">
            <div class="header-logo">
              <img
                class="desktop-logo"
                src="../assets/TRT_logo_serif.png"
                alt="tomorrow ready texas logo"
              />
              <div class="mobile-logo">
                <img
                  src="../assets/TRT_logo_mobile.png"
                  alt="tomorrow ready texas mobile logo"
                />
              </div>
            </div>
            <!-- <img
              class="globe-icon"
              src="../assets/globe.svg"
              alt="language icon"
            />
            <div class="language">
              <b-nav-item-dropdown
                class="language-selection"
                :text="`${lang_info.lang_name}` + ' (US)'"
              >
                <b-button
                  v-if="c_lang_info.lang_code !== 'en'"
                  @click="lang_select('en')"
                  variant="link"
                  >English (US)
                </b-button>

                <b-button
                  v-if="c_lang_info.lang_code !== 'es'"
                  @click="lang_select('es')"
                  variant="link"
                  >Español
                </b-button>
              </b-nav-item-dropdown>
            </div> -->
          </b-navbar-brand>
        </div>

        <div>
          <b-navbar-toggle target="nav-collapse-mobile"></b-navbar-toggle>

          <b-collapse id="nav-collapse-mobile" is-nav>
            <div
              v-show="userState.status.loggedIn && userState.profile.is_active"
              class="login-items"
            >
              <div
                class="multi-level"
                id="multi-level-mobile"
                v-show="
                  userState.status.loggedIn && userState.profile.is_active
                "
                style="display: block"
              >
                <b-navbar-toggle
                  target="nav-collapse-mobile"
                  style="border-color: transparent; position: absolute"
                >
                  <label class="close-menu">
                    <img
                      class="close-menu-icon"
                      src="../assets/close-icon.svg"
                      alt="close dropdown menu icon"
                    />
                  </label>
                </b-navbar-toggle>
                <div class="action-plan-title">
                  {{ lang_stanza.action_plans }}
                </div>
                <div v-for="gp in grade_pathway_list" :key="gp.grade_key">
                  <div class="grade-item" :id="`${gp.grade_key}-mobile`">
                    <input
                      type="checkbox"
                      :id="`${gp.grade_key_suffix}-mobile`"
                      class="grade-checkboxes"
                    />
                    <label :for="`${gp.grade_key_suffix}-mobile`"
                      >{{ gp.grade_title }}<i class="arrow down"></i
                    ></label>
                    <ul
                      v-for="pathway in gp.pathway_list"
                      :key="pathway.grade_pathway_id"
                    >
                      <li>
                        <a
                          :href="`/${lang_info.lang_code}/action-plan/${gp.grade_key}/${pathway.pathway_key}`"
                        >
                          {{ pathway.pathway_title }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="account-profile-links">
                  <a :href="`/${lang_info.lang_code}/profile`">
                    <img
                      id="account-icon-dropdown"
                      src="../assets/account-circle.svg"
                      alt="user icon"
                    />
                    <div>
                      {{ lang_stanza.profile_edit_profile }}
                    </div>
                  </a>
                </div>
                <div class="logout-btn" @click="do_logout">
                  <a :href="`/${lang_info.lang_code}/profile`">
                    <img
                      id="logout-icon-dropdown"
                      src="../assets/log_out.svg"
                      alt="log out icon"
                    />
                    {{ lang_stanza.logout }}
                  </a>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>

        <b-collapse id="nav-collapse" is-nav>
          <!-- <div v-show="status.loggedIn" class="login-items"> -->
          <div
            v-show="userState.status.loggedIn && userState.profile.is_active"
            class="login-items"
          >
            <!-- <img
              class="globe-icon"
              src="../assets/globe.svg"
              alt="language icon"
            />
            <div class="language">
              <b-nav-item-dropdown
                class="language-selection logged-in-lang"
                :text="`${lang_info.lang_name}` + ' (US)'"
              >
                <b-button
                  v-if="c_lang_info.lang_code !== 'en'"
                  @click="lang_select('en')"
                  variant="link"
                  >English (US)
                </b-button>
                <b-button
                  v-if="c_lang_info.lang_code !== 'es'"
                  @click="lang_select('es')"
                  variant="link"
                  >Español
                </b-button>
              </b-nav-item-dropdown>
            </div> -->
            <!-- <div class="search-items">
              <img
                  class="search-icon"
                  src="../assets/search-icon.svg"
                  alt="search icon"
              />
              <span class="search-text">Search</span>
            </div> -->

            <div
              v-show="userState.status.loggedIn && userState.profile.is_active"
              class="profile-menu"
              id="profile-menu"
            >
              <!-- <div>Account</div> -->
              <!-- <div v-if="userState.status.loggedIn" class="profile-menu"> -->
              <div class="profile-menu-dropdown">
                <div id="profile-menu-nav-link">
                  <input type="checkbox" id="profile-menu-checkbox" />
                  <label
                    for="profile-menu-checkbox"
                    id="profile-label"
                    @click="toggleProfileMenu"
                    class="profile-area"
                  >
                    <img
                      id="account-icon"
                      class="profile-area"
                      src="../assets/account-circle.svg"
                      alt="user icon"
                    />
                    <img
                      id="account-icon-blue"
                      class="profile-area"
                      src="../assets/acct-img-blue.svg"
                      alt="user icon"
                    />
                    Account
                  </label>
                  <div id="profile-menu-dropdown-area" class="profile-area">
                    <div id="user-info" class="profile-area">
                      <div class="username">
                        {{
                          `${userState.profile.first_name} ${userState.profile.last_name}`
                        }}
                      </div>
                      <div class="user-email">
                        {{ userState.profile.email }}
                      </div>
                    </div>
                    <div id="profile-button" class="profile-area">
                      <img
                        id="account-icon-dropdown"
                        src="../assets/account-circle.svg"
                        alt="user icon"
                      />
                      <!-- <button :to="`/${lang_info.lang_code}/profile`">
                        {{ lang_stanza.profile_edit_profile }}
                      </button> -->
                      <div id="profile-button" @click="go_to_profile">
                        {{ lang_stanza.profile_edit_profile }}
                      </div>
                    </div>
                    <div class="logout-btn" @click="do_logout">
                      <img
                        id="logout-icon-dropdown"
                        src="../assets/log_out.svg"
                        alt="log out icon"
                      />
                      <div>{{ lang_stanza.logout }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 
              <b-nav-item-dropdown class="profile-menu-dropdown" no-caret right>
                <template slot="button-content" id="account-dropdown">
                  <img
                    id="account-icon"
                    src="../assets/account-circle.svg"
                    alt="user icon"
                />Account</template>
                <div class="profile-menu-content">
                  <b-dropdown-text class="username"
                    >{{
                      `${userState.profile.first_name} ${userState.profile.last_name}`
                    }}
                  </b-dropdown-text>
                  <b-dropdown-text class="user-email"
                    >{{ userState.profile.email }}
                  </b-dropdown-text>
                  <b-dropdown-item class="profile-button">
                    <b-button
                      id="profile-button"
                      :to="`/${lang_info.lang_code}/profile`"
                      >{{ lang_stanza.profile_edit_profile }}
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item
                    class="account-profile-links"
                    :to="`/${lang_info.lang_code}/password-reset`"
                    >{{ lang_stanza.profile_edit_password }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    class="account-profile-links"
                    :to="`/${lang_info.lang_code}/profile#preferences`"
                    >{{ lang_stanza.customize_preferences }}
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item class="logout-btn" @click="do_logout"
                    >{{ lang_stanza.logout }}
                  </b-dropdown-item>
                  <b-modal id="bv-modal-example" hide-footer>
                    <template class="modalTitle" #modal-title>
                      Log out successful
                    </template>
                    <div class="d-block">
                      <p>
                        You have successfully logged out. We hope to see you
                        back soon! if you logged out by mistake, click below to
                        Log back in
                      </p>
                    </div>
                    <b-button
                      class="mt-3"
                      block
                      @click="$bvModal.hide('bv-modal-example')"
                      >OK
                    </b-button>
                  </b-modal>
                </div>
              </b-nav-item-dropdown> -->

              <!-- Site Navigation Dropdown -->
              <div class="site-nav" id="site-nav" style="width: 0px !important">
                <input type="checkbox" id="site-nav-menu" />
                <label @click="openMenu" for="site-nav-menu">
                  <!-- <img
                    class="hamburger-menu"
                    id="hamburger-menu"
                    src="../assets/hamburger.svg"
                    alt="dropdown menu icon"
                  /> -->
                  <span class="menu-span" id="menu-span"
                    >{{ lang_stanza.action_plans }} <i class="arrow down"></i
                  ></span>
                  <!-- <input type="checkbox" id="hamburger-menu" class="hamburger-menu"/>
                  <label for="hamburger-menu">
                    {{ "Action Plans" }}<i class="arrow down"></i>
                  </label> -->
                </label>
                <div class="multi-level" id="multi-level">
                  <!-- <label @click="closeMenu" class="close-menu">
                    <img
                      class="close-menu-icon"
                      src="../assets/close-icon.svg"
                      alt="close dropdown menu icon"
                    />
                  </label> -->
                  <!-- <div id="grade-items"> -->
                  <div
                    v-for="gp in grade_pathway_list"
                    :key="gp.grade_key"
                    class="parent-grade-item"
                  >
                    <div class="grade-item" :id="`${gp.grade_key}`">
                      <input
                        type="checkbox"
                        :id="`${gp.grade_key_suffix}`"
                        class="grade-checkboxes"
                      />
                      <label :for="`${gp.grade_key_suffix}`"
                        >{{ gp.grade_title }}<i class="arrow down"></i
                      ></label>
                      <ul
                        v-for="pathway in gp.pathway_list"
                        :key="pathway.grade_pathway_id"
                        class="grade-item-path"
                      >
                        <li>
                          <a
                            :href="`/${lang_info.lang_code}/action-plan/${gp.grade_key}/${pathway.pathway_key}`"
                          >
                            {{ pathway.pathway_title }}
                          </a>
                          <!--
                            <router-link variant="link"
                                        :to="`/action-plan/${user_grade.grade_key}/${pathway.pathway_key}`">
                              {{ pathway.pathway_title }}
                            </router-link>
                            -->
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
              <!-- End of Site Navigation Dropdown -->
            </div>
          </div>
        </b-collapse>

        <div
          v-if="!userState.profile && landingPage()"
          class="logged-out-items"
        >
          <div class="landing-buttons">
            <router-link
              tag="b-button"
              :to="{ name: 'login' }"
              id="login-button"
              >{{ lang_stanza.login }}
            </router-link>
            <router-link
              tag="b-button"
              :to="{ name: 'registration' }"
              id="get-started-button"
              >{{ lang_stanza.get_started }}
            </router-link>
          </div>
        </div>
      </b-navbar>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueCookies from "vue-cookies";
import { sessionStorage } from "../_helpers";
import dateformat from "dateformat";

export default {
  name: "Header",
  data() {
    return {
      destination: "",
      refresh: 0,
      lang_stanza_key: "header",
      lang_stanza: {},
      lang_info: {},
      is_multiLevel_menu_open: false,
      is_profile_menu_open: false,
    };
  },
  async mounted() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    let sessionUserProfile = sessionStorage.decodeUserSession();
    if (sessionUserProfile && sessionUserProfile.sub) {
      await this.getUserProfileByOidc(sessionUserProfile.sub);
      //if (!this.userProfile) {
      //}
      this.refresh++;
    } else {
      await this.clearUserProfile();
      this.logout();
      this.refresh++;
      //for pushing
    }

    await this.refresh_grade_pathway_list();

    //clicking off of menu
    document.addEventListener(
      "mouseup",
      function (e) {
        let menu = document.getElementById("multi-level");
        let profile_menu = document.getElementById(
          "profile-menu-dropdown-area"
        );
        let arrow = document.getElementById("site-nav-menu");
        let profile_arrow = document.getElementById("profile-menu-checkbox");
        let clicked = e.target.className;
        let names = ["hamburger-menu", "menu-span", "arrow down"];
        let profile_names = [
          "profile-area",
          "username",
          "user-email",
          "profile-menu-dropdown",
          "profile-menu-nav-link",
        ];
        if (
          this.is_multiLevel_menu_open &&
          !menu.contains(e.target) &&
          !names.includes(clicked) &&
          menu
        ) {
          this.openMenu();
          arrow.checked = false;
        } else if (
          this.is_profile_menu_open &&
          !profile_menu.contains(e.target) &&
          !profile_names.includes(clicked) &&
          profile_menu
        ) {
          this.toggleProfileMenu();
          profile_arrow.checked = false;
        }
      }.bind(this)
    );

    document.addEventListener(
      "click",
      function (e) {
        let obj = e.target.className;
        if (obj.includes("grade-checkboxes")) {
          this.toggleGradeMenus(e.target);
        }
      }.bind(this)
    );

    //  window.onload = function(){
    //         var hideMe = document.getElementById('multi-level');
    //         document.onclick = function(e){
    //            if(e.target.id == 'hideMe'){
    //               hideMe.style.display = 'none';
    //            }
    //         };
    //      };
  },
  updated() {
    let multiLevel = document.getElementById("multi-level");
    let profileMenu = document.getElementById("profile-menu");
    if (multiLevel.style.display === "block") {
      multiLevel.style.display = "none";
    }
    profileMenu.style.display = "flex";
  },
  methods: {
    ...mapActions("registration", ["logout"]),
    ...mapActions("account", [
      "getUserProfileByOidc",
      "clearUserProfile",
      "persistDateLastLogin",
      "persistDateUpdate",
    ]),
    ...mapActions("language", [
      "persistLangChoice",
      "getLangInfo",
      "getLangStanza",
    ]),
    ...mapActions("preferences", [
      "getUserGradePathwayList",
      "getGradePathwayList",
    ]),
    // ...mapActions({
    //  clearAlert: "alert/clear",
    // }),
    homeButton() {
      if (this.loggedIn) {
        this.destination = "/home";
      } else {
        this.destination = "/landing";
      }
    },
    async do_logout() {
      await this.clearUserProfile();
      await this.logout();
      document.location.pathname = `/${this.lang_info.lang_code}/home`;
    },
    async go_to_profile() {
      document.location.pathname = `/${this.lang_info.lang_code}/profile`;
    },
    async refresh_grade_pathway_list() {
      // let sessionUserProfile = sessionStorage.decodeUserSession();
      // if (sessionUserProfile && sessionUserProfile.sub) {
      await this.getGradePathwayList({
        lang_code: this.lang_info["lang_code"],
      });
      // }
    },
    async lang_select(lang_code) {
      const current_lang_code = this.lang_info["lang_code"];

      let now = new Date();
      let ts_update = dateformat(now, "yyyy-mm-dd HH:MM:ss.l");

      const packet_dict = {
        oidc_id: this.userProfile.oidc_id,
        lang_code: lang_code,
        ts_update: ts_update,
      };
      await this.persistLangChoice(packet_dict);
      this.lang_info = await this.getLangInfo();
      this.lang_stanza = await this.getLangStanza({
        lang_code: lang_code,
        lang_stanza_key: this.lang_stanza_key,
      });

      if (lang_code !== current_lang_code) {
        let current_route_path = this.$router.currentRoute.path;
        let re_path = RegExp(`^/${current_lang_code}/(.*)$`);
        let re_match = current_route_path.match(re_path);

        let new_path = `/${lang_code}/${re_match[1]}`;

        let query = this.$router.currentRoute.query;

        let q_str = Object.keys(query)
          .map((k) => {
            return `${k}=${query[k]}`;
          })
          .join("&");

        if (q_str) {
          new_path = `${new_path}?${q_str}`;
        }
        document.location.href = new_path;
      }
    },
    landingPage() {
      return this.$route.name === "landing";
    },
    openMenu: async function () {
      let multiLevel = document.getElementById("multi-level");
      let siteNav = document.getElementById("site-nav");
      if (this.is_multiLevel_menu_open) {
        multiLevel.style.display = "none";
        siteNav.style.width = "0px";
        this.is_multiLevel_menu_open = false;
      } else {
        await this.refresh_grade_pathway_list();
        multiLevel.style.display = "block";
        this.is_multiLevel_menu_open = true;
      }
      // if (this.is_profile_menu_open){
      //   profile_dropdown.style.display = "none";
      //   this.is_profile_menu_open = false;
      // }
      // else if (!this.is_profile_menu_open) {
      //   profile_dropdown.style.display = "block";
      //   this.is_profile_menu_open = true;
      // }
    },
    async toggleProfileMenu() {
      let profile_dropdown = document.getElementById(
        "profile-menu-dropdown-area"
      );
      if (this.is_profile_menu_open) {
        profile_dropdown.style.display = "none";
        this.is_profile_menu_open = false;
      } else {
        profile_dropdown.style.display = "block";
        this.is_profile_menu_open = true;
      }
    },
    async toggleGradeMenus(e) {
      let inputs = document.querySelectorAll(".grade-checkboxes");
      inputs.forEach((box) => {
        if (box.id != e.id) {
          box.checked = false;
        }
      });
    },
    change_img() {
      if (document.getElementById("profile-menu-checkbox").checked) {
        document.getElementById("profile-label").innerHTML ="<img id='account-icon' class='profile-area' src=../assets/account-circle.svg' alt='user icon'>";
      }
    },
  },
  computed: {
    ...mapState("registration", ["status"]),
    ...mapState("account", ["userProfile"]),
    ...mapState("preferences", [
      "user_grade_pathway_list",
      "grade_pathway_list",
    ]),
    // ...mapState({
    //   alert: (state) => state.alert,
    // }),
    userState() {
      // this.refresh++;
      let user = {};
      user = { profile: this.userProfile, status: this.status };
      return user;
    },
    c_lang_info() {
      return this.lang_info;
    },
  },
  watch: {
    $route(to, from) {
      document.title = "Tomorrow Ready Texas";
    },
  },
};
</script>

<style lang="scss">
@import "../design/_header";
</style>
