<template>
  <div id="app">
    <div>
      <main>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-53CTZ4K"
            height="0"
            width="0"
            style="display: none; visibility: hidden"
          >
          </iframe>
        </noscript>
        <div>
          <!-- <div style="position: relative">
              <div v-if="alert.message" :class="'alert ' + alert.type">
                {{ alert.message }}
              </div>
            </div> -->
          <div>
            <router-view />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: {},
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),
  },
  watch: {
    $route(to, from) {
      document.title = "Tomorrow Ready Texas";
      this.clearAlert();
    },
  },
  created() {
    document.title = "Tomorrow Ready Texas";
  },
};
</script>

<style lang="scss">
@import "@/design/index.scss";
</style>
