import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

import { registration } from './registration.module';
import { preferences } from './preferences.module';
import { account } from './account.module';
import { content } from './content.module';
import { language } from './language.module'
import { alert } from './alert.module';
import { admin } from './admin.module';

Vue.use(Vuex);

// 'language.lang',

const plugin_session = createPersistedState({
    paths:[ 'account.userProfile',
            'language.lang_dict',
            'content.validationData',
            'preferences.user_grade_pathway_list'
    ]
})

export const store = new Vuex.Store({
    modules: {
        registration,
        preferences,
        account,
        content,
        language,
        alert,
        admin,
    },
    plugins: [plugin_session]
});