<template>
  <div :key="refresh">
    <b-navbar-nav>
      <b-navbar toggleable="lg" type="light" class="header-body">
        <div v-show="status.loggedIn">
          <b-navbar-brand :to="`/${lang_info.lang_code}/home`">
            <div class="header-logo">
              <img
                class="desktop-logo"
                src="../assets/TRT_logo_serif.png"
                alt="tomorrow ready texas logo"
              />
              <div class="mobile-logo"><img src="../assets/TRT_logo_mobile.png" alt="tomorrow ready texas mobile logo"></div>
            </div>
          </b-navbar-brand>
        </div>

        <div v-show="!status.loggedIn">
          <b-navbar-brand :to="`/${lang_info.lang_code}/landing`">
            <div class="header-logo">
              <img
                class="desktop-logo"
                src="../assets/TRT_logo_serif.png"
                alt="tomorrow ready texas logo"
              />
              <div class="mobile-logo"><img src="../assets/TRT_logo_mobile.png" alt="tomorrow ready texas mobile logo"></div>
            </div>
            <!-- <img
              class="globe-icon"
              src="../assets/globe.svg"
              alt="language icon"
            />
            <div class="language">
              <b-nav-item-dropdown
                class="language-selection"
                :text="`${lang_info.lang_name}` + ' (US)'"
              >
                <b-button
                  v-if="c_lang_info.lang_code !== 'en'"
                  @click="lang_select('en')"
                  variant="link"
                  >English (US)</b-button
                >
                <b-button
                  v-if="c_lang_info.lang_code !== 'es'"
                  @click="lang_select('es')"
                  variant="link"
                  >Español</b-button
                >
              </b-nav-item-dropdown>
            </div> -->
          </b-navbar-brand>
        </div>
        <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
        <b-collapse id="nav-collapse" is-nav>
          <!-- <div v-show="status.loggedIn" class="login-items"> -->
        </b-collapse>
        <div v-if="!status.loggedIn && landingPage()" class="logged-out-items">
          <div class="landing-buttons">
            <router-link
              tag="b-button"
              :to="{ name: 'login' }"
              id="login-button"
              >{{ lang_stanza.login }}
            </router-link>
            <router-link
              tag="b-button"
              :to="{ name: 'registration' }"
              id="get-started-button"
              >{{ lang_stanza.get_started }}
            </router-link>
          </div>
        </div>
      </b-navbar>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueCookies from "vue-cookies";
import { sessionStorage } from "../_helpers";
import dateformat from "dateformat";
export default {
  name: "HeaderPublic",
  data() {
    return {
      destination: "",
      refresh: 0,
      lang_stanza_key: "header",
      lang_stanza: {},
      lang_info: {},
    };
  },
  async mounted() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });
    await this.refresh_grade_pathway_list();
    let sessionUserProfile = sessionStorage.decodeUserSession();
    if (sessionUserProfile && sessionUserProfile.sub) {
      await this.getUserProfileByOidc(sessionUserProfile.sub);
    }
    /*
    document.addEventListener("mouseup", function (e) {
      let menu = document.getElementById("multi-level");
      if (!menu.contains(e.target) && menu.style.display === "block") {
        this.closeMenu()
      }
    }.bind(this));
     */
    //  window.onload = function(){
    //         var hideMe = document.getElementById('multi-level');
    //         document.onclick = function(e){
    //            if(e.target.id == 'hideMe'){
    //               hideMe.style.display = 'none';
    //            }
    //         };
    //      };
  },
  updated() {
    let multiLevel = document.getElementById("multi-level");
    let profileMenu = document.getElementById("profile-menu");
    /*
    if (multiLevel.style.display === "block") {
      multiLevel.style.display = "none"
    }
    profileMenu.style.display = "block"
     */
  },
  methods: {
    ...mapActions("registration", ["logout"]),
    ...mapActions("account", [
      "getUserProfileByOidc",
      "clearUserProfile",
      "persistDateLastLogin",
      "persistDateUpdate",
    ]),
    ...mapActions("preferences", [
      "getUserGradePathwayList",
      "getGradePathwayList",
    ]),
    ...mapActions("language", [
      "persistLangChoice",
      "getLangInfo",
      "getLangStanza",
    ]),
    // ...mapActions({
    //     clearAlert: "alert/clear",
    // }),
    homeButton() {
      if (this.loggedIn) {
        this.destination = "/home";
      } else {
        this.destination = "/landing";
      }
    },
    async do_logout() {
      await this.clearUserProfile();
      await this.logout();
    },
    async refresh_grade_pathway_list() {
      let sessionUserProfile = sessionStorage.decodeUserSession();
      // if (sessionUserProfile && sessionUserProfile.sub) {
      await this.getGradePathwayList({
        lang_code: this.lang_info["lang_code"],
      });
      // }
    },
    async lang_select(lang_code) {
      const current_lang_code = this.lang_info["lang_code"];
      const packet_dict = {
        lang_code: lang_code,
      };
      if (this.userProfile.oidc_id) {
        let now = new Date();
        let ts_update = dateformat(now, "yyyy-mm-dd HH:MM:ss.l");
        packet_dict["oidc_id"] = this.userProfile.oidc_id;
        packet_dict["ts_update"] = ts_update;
      }
      await this.persistLangChoice(packet_dict);
      this.lang_info = await this.getLangInfo();
      this.lang_stanza = await this.getLangStanza({
        lang_code: lang_code,
        lang_stanza_key: this.lang_stanza_key,
      });
      if (lang_code !== current_lang_code) {
        let current_route_name = this.$router.currentRoute.name;
        let query = this.$router.currentRoute.query;
        let q_str = Object.keys(query)
          .map((k) => {
            return `${k}=${query[k]}`;
          })
          .join("&");
        let new_path = `/${lang_code}/${current_route_name}`;
        if (q_str) {
          new_path = `${new_path}?${q_str}`;
        }
        document.location.href = new_path;
      }
    },
    landingPage() {
      return this.$route.name === "landing";
    },
    openMenu: async function () {
      let multiLevel = document.getElementById("multi-level");
      let menuSpan = document.getElementById("menu-span");
      let hamburgerMenu = document.getElementById("hamburger-menu");
      let accountIcon = document.getElementById("account-icon");
      if (multiLevel.style.display === "block") {
        multiLevel.style.display = "none";
        menuSpan.style.left = "35px";
        hamburgerMenu.style.left = "8px";
        accountIcon.style.left = "0px";
      } else {
        multiLevel.style.display = "block";
        menuSpan.style.left = "499px";
        hamburgerMenu.style.left = "472px";
        accountIcon.style.left = "464px";
      }
    },
    async closeMenu() {
      let multiLevel = document.getElementById("multi-level");
      let menuSpan = document.getElementById("menu-span");
      let hamburgerMenu = document.getElementById("hamburger-menu");
      let accountIcon = document.getElementById("account-icon");
      if (multiLevel.style.display === "none") {
        multiLevel.style.display = "block";
        await this.refresh_grade_pathway_list();
      } else {
        multiLevel.style.display = "none";
        menuSpan.style.left = "35px";
        hamburgerMenu.style.left = "8px";
        accountIcon.style.left = "0px";
      }
    },
  },
  computed: {
    ...mapState("registration", ["status"]),
    ...mapState("account", ["userProfile"]),
    ...mapState("preferences", [
      "user_grade_pathway_list",
      "grade_pathway_list",
    ]),
    //...mapState({
    //  alert: (state) => state.alert,
    //}),
    userState() {
      // this.refresh++;
      let user = {};
      user = { profile: this.userProfile, status: this.status };
      return user;
    },
    c_lang_info() {
      return this.lang_info;
    },
  },
  watch: {
    $route(to, from) {
      document.title = "Tomorrow Ready Texas";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../design/_headerPublic";
</style>
